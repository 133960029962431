import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RepherrerForm from './repherrer';
import RepherreeForm from './repherree';
import { withAuthorization } from '../Session';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { LoginLink } from '../Login';
import * as ROUTES from '../../constants/routes';

const styles = theme => ({
  card: {
    minWidth: 275,
    maxWidth: 600,
  },
});

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRepherree: true,
      isRepherrer: !!this.props.location && !!this.props.location.state && this.props.location.state.registrationType === 'repherrer',
    };
  }

  onChangeCheckbox = name => event => {
    if (event.target.checked) {
      this.setState({ [name]: event.target.checked });
      return;
    }

    // Don't let user uncheck both.
    const { isRepherrer, isRepherree } = this.state;
    if (!isRepherrer && name === 'isRepherree') {
      return;
    }
    if (!isRepherree && name === 'isRepherrer') {
      return;
    }
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingTop: '20px' }}>
        <Card className={classes.card}>
          <CardContent>
            <FormGroup column>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isRepherree}
                    onChange={this.onChangeCheckbox('isRepherree')}
                    value="isRepherree"
                    color="secondary"
                  />
                }
                label="I want referrals"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isRepherrer}
                    onChange={this.onChangeCheckbox('isRepherrer')}
                    value="isRepherrer"
                    color="primary"
                  />
                }
                label="I want to refer others"
              />
            </FormGroup>
            {this.state.isRepherrer ? (
              <RepherrerForm />
            ) : (
              <RepherreeForm />
            )}
            <LoginLink />
          </CardContent>
        </Card>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const RegisterLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.REGISTER}>Register</Link>
  </p>
);

export default withAuthorization(false)(
  withStyles(styles)(RegisterPage),
);

export { RegisterLink };
