import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import RepherralItem from './RepherralItem';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import * as COMPANIES from '../../constants/companies';

class CompanyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      repherrals: [],
      limit: 10,
      reachedEnd: false,
      startScrolling: false,
    };
  }

  componentDidMount() {
    this.onListenForRepherrals();
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.company === this.props.company) {
      return;
    }
    this.onListenForRepherrals();
  }

  componentWillUnmount() {
    this.props.firebase.repherrals().off();
    document.removeEventListener('scroll', this.trackScrolling);
  }

  onListenForRepherrals = () => {
    this.setState({ loading: true });

    const currLength = this.state.repherrals.length;
    this.props.firebase
      .repherrals()
      .orderByChild('companies/' + this.props.company)
      .equalTo(true)
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const repherralObject = snapshot.val();
        if (repherralObject) {
          const CompanyList = Object.keys(repherralObject).map(
            key => ({
              ...repherralObject[key],
              id: key,
            }),
          );
          CompanyList.reverse();

          this.setState({
            repherrals: CompanyList,
            loading: false,
            reachedEnd: currLength === CompanyList.length,
          });
        } else {
          this.setState({ repherrals: null, loading: false });
        }
      });
  };

  onNextPage = () => {
    this.setState(
      state => ({
        limit: Math.min(state.limit + 10, 150),
        startScrolling: true,
      }),
      this.onListenForRepherrals,
    );
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this.isBottom(wrappedElement)) {
      if (this.state.reachedEnd) {
        document.removeEventListener('scroll', this.trackScrolling);
      } else if (this.state.startScrolling) {
        this.onNextPage();
      }
    }
  };

  render() {
    const { repherrals, loading } = this.state;
    const avatar = {
      margin: '5px',
      width: 50,
      height: 50,
      border: '4px solid transparent',
      backgroundImage:
        'linear-gradient(white, white), linear-gradient(to right, red,orange)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      display: 'inline-block',
    };
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <div style={{ textAlign: 'center' }}>
              <Avatar
                style={avatar}
                src={COMPANIES.IMAGES[this.props.company]}
              />
            </div>
            {loading && (
              <div style={{ textAlign: 'center' }}>Loading ...</div>
            )}
            {repherrals && (
              <div>
                {repherrals.map(repherral => (
                  <div
                    key={repherral.id}
                    style={{ marginBottom: '20px' }}
                  >
                    <RepherralItem
                      authUser={authUser}
                      repherral={repherral}
                      onRemoveRepherral={this.props.onRemoveRepherral}
                    />
                  </div>
                ))}
              </div>
            )}
            {!loading && repherrals && !this.state.startScrolling && (
              <Button
                style={{
                  fullWidth: true,
                }}
                variant="outlined"
                fullWidth
                color="primary"
                onClick={this.onNextPage}
              >
                More referral requests to{' '}
                {COMPANIES.NAMES[this.props.company]}
              </Button>
            )}
            {!repherrals && (
              <div>There are no referral requests ...</div>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(CompanyList);
