import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import RepherralItem from './RepherralItem';
import { withFirebase } from '../Firebase';

class RepherralPreview extends Component {

  onRemoveRepherral = () => {
    return;
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <RepherralItem
              isPreview
              authUser={authUser}
              repherral={this.props.repherral}
              onRemoveRepherral={this.onRemoveRepherral}
            />
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(RepherralPreview);
