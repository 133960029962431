import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';

import { withAuthorization } from '../Session';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { RegisterLink } from '../Register';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const styles = theme => ({
  card: {
    minWidth: 275,
    maxWidth: 600,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    marginTop: 30,
    marginBottom: 30,
    fullWidth: true,
  },
});

class LoginPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingTop: '20px' }}>
        <Card className={classes.card}>
          <CardContent>
            <LoginForm />
            <PasswordForgetLink />
            <RegisterLink />
          </CardContent>
        </Card>
      </div>
    );
  }
}

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class LoginFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { email, password, error } = this.state;
    const { classes } = this.props;

    return (
      <form className={classes.container} onSubmit={this.onSubmit}>
        {error && <p style={{ color: 'red' }}>{error.message}</p>}
        <TextField
          className={classNames(classes.margin, classes.textField)}
          fullWidth
          error={!!error}
          name="email"
          label="Email Address"
          placeholder="you@yourcompany.com"
          margin="normal"
          variant="outlined"
          type="email"
          value={email}
          onChange={this.onChange}
        />
        <TextField
          className={classNames(classes.margin, classes.textField)}
          fullWidth
          error={!!error}
          variant="outlined"
          name="password"
          type={this.state.showPassword ? 'text' : 'password'}
          margin="normal"
          label="Password"
          value={password}
          onChange={this.onChange}
        />
        <Button
          className={classNames(classes.button)}
          variant="contained"
          fullWidth
          color="primary"
          type="submit"
        >
          Login
        </Button>
      </form>
    );
  }
}

LoginFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const LoginForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles),
)(LoginFormBase);

const LoginLink = () => (
  <p>
    Already have an account? <Link to={ROUTES.LOGIN}>Login</Link>
  </p>
);

export { LoginLink };

export default withAuthorization(false)(
  withStyles(styles)(LoginPage),
);

export { LoginForm };
