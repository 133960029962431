import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import RepherralItem from './RepherralItem';
import Button from '@material-ui/core/Button';
import * as COMPANIES from '../../constants/companies';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';

class HomeList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            repherrals: [],
        };

        this.resetStates();
    };

    resetStates() {
        this.limit = 30;
    };

    componentDidMount() {
        this.onListenForRepherrals();
    };

    componentWillUnmount() {
        this.props.firebase.repherrals().off();
    };

    onListenForRepherrals = () => {
        let limit = this.limit;
        this.fetching = true;

        this.props.firebase
            .topRepherrals()
            .limitToLast(limit)
            .once('value', snapshot => {
                this.processServerResponse(snapshot);
            });
    };

    processServerResponse(snapshot) {
        const repherralObject = snapshot.val();
        if (repherralObject) {
        // Filter works by adding a display field.
        let repherrals = [];
        let max = 1;
        for (const key of Object.keys(repherralObject).reverse()) {
            let repherral = {
            ...repherralObject[key],
            id: key,
            showTooltip: repherralObject[key].isVerifiedWorkplace ? max-- > 0 : false,
            };
            if (this.isTopCandidate(repherral)) {
                repherrals.push(repherral);
            }
        }
        this.setState({
            repherrals,
        });
        } else {
        this.setState({
            repherrals: [],
        });
        }
    };

    isTopCandidate(repherral) {
        let isTopCompany = repherral.prevExp.toLowerCase() in COMPANIES.DESTS;
        return isTopCompany || repherral.isVerifiedWorkplace;
    }

    onClick = () => {
        this.props.history.push({
            pathname: ROUTES.REGISTER,
            state: { registrationType: 'repherrer' },
        });
    };

    render() {
        const { repherrals } = this.state;
        return (
            <div>
                <div style={{ minWidth: '600px' }}>
                </div>

                {/* Filtered referral list. */}
                {repherrals && (
                <div>
                    {repherrals.map(repherral => (
                    <div
                        key={repherral.id}
                        style={{
                        marginTop: '15px',
                        marginBottom: '15px',
                        }}
                    >
                        <RepherralItem
                        repherral={repherral}
                        onRemoveRepherral={this.props.onRemoveRepherral}
                        isFake={repherral.showTooltip}
                        />
                    </div>
                    ))}
                </div>
                )}

                {repherrals.length > 0 && (
                <Button
                    fullWidth
                    variant="contained"
                    onClick={this.onClick}
                    color="primary"
                >
                    REGISTER TO SEE MORE
                </Button>
                )}
            </div>
        );
    };
}

export default withFirebase(withRouter(HomeList));
