import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import Repherrals from '../Repherrals';

class RepherralDetailsPage extends Component {
	render() {
		return (
			<div style={{ paddingTop: '20px' }}>
				<Repherrals showDetails id={this.props.match.params.id} />
			</div>
		);
	}
}

export default compose(withFirebase)(RepherralDetailsPage);
