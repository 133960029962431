import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  card: {
    minWidth: 275,
    maxWidth: 600,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    marginTop: 30,
    marginBottom: 30,
    fullWidth: true,
  },
});

class PasswordForgetPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingTop: '20px' }}>
        <Card className={classes.card}>
          <CardContent>
            <PasswordForgetForm />
          </CardContent>
        </Card>
      </div>
    );
  }
}

const INITIAL_STATE = {
  email: '',
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    if (
      email === 'kyongtai.alex.min@gmail.com' ||
      email === 'alexmin@google.com'
    ) {
      event.preventDefault();
      return;
    }

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        // Do nothing.
        return;
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email } = this.state;
    const { classes } = this.props;

    return (
      <form className={classes.container} onSubmit={this.onSubmit}>
        <TextField
          className={classNames(classes.margin, classes.textField)}
          fullWidth
          name="email"
          label="Email Address"
          placeholder="you@yourcompany.com"
          margin="normal"
          variant="outlined"
          value={email}
          type="email"
          onChange={this.onChange}
        />
        <Button
          className={classNames(classes.button)}
          variant="contained"
          fullWidth
          color="primary"
          type="submit"
        >
          Reset Password
        </Button>
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot your password?</Link>
  </p>
);

PasswordForgetFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

PasswordForgetPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PasswordForgetPage);

const PasswordForgetForm = withFirebase(
  withStyles(styles)(PasswordForgetFormBase),
);

export { PasswordForgetForm, PasswordForgetLink };
