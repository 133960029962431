import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

import * as COMPANIES from '../../constants/companies';

const suggestions = COMPANIES.LIST;

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingLeft: '8px',
    paddingRight: '22px',
    marginBottom: theme.spacing.unit,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 3,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, error, ref, ...other } = inputProps;

  return (
    <TextField
      error={error}
      variant="outlined"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    (selectedItem || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.key}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      <Avatar
        src={COMPANIES.IMAGES[suggestion.key]}
        style={{ margin: 15, width: 30, height: 30 }}
      />
      {suggestion.name}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.object,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(authUser, value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        // Filter out auth user's company.
        const isAuthUserCompany =
          !!authUser && authUser.domain === suggestion.key;
        const keep =
          count < 5 &&
          suggestion.name.slice(0, inputLength).toLowerCase() ===
            inputValue &&
          !isAuthUserCompany;
        if (keep) {
          count += 1;
        }

        return keep;
      });
}

class MultiSelectInput extends React.Component {
  state = {
    inputValue: '',
    selectedItem: [],
  };

  updateSelectedItemCallback = () => {
    this.props.onCompaniesChange(this.state.selectedItem);
  };

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace'
    ) {
      this.setState(
        {
          selectedItem: selectedItem.slice(
            0,
            selectedItem.length - 1,
          ),
        },
        this.updateSelectedItemCallback,
      );
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = item => {
    let { selectedItem } = this.state;

    // Don't add company of auth user. Don't add over 8.
    if (
      selectedItem.indexOf(item) === -1 &&
      this.props.authUser.domain !== item &&
      selectedItem.length < 8
    ) {
      selectedItem = [...selectedItem, item];
    }

    this.setState(
      {
        inputValue: '',
        selectedItem,
      },
      this.updateSelectedItemCallback,
    );
  };

  handleDelete = item => () => {
    this.setState(state => {
      const selectedItem = [...state.selectedItem];
      selectedItem.splice(selectedItem.indexOf(item), 1);
      return { selectedItem };
    }, this.updateSelectedItemCallback);
  };

  render() {
    const { classes, error } = this.props;
    const { inputValue, selectedItem } = this.state;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex,
        }) => (
          <div className={classes.container}>
            {renderInput({
              error: error,
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                startAdornment: selectedItem.map(item => (
                  <Chip
                    avatar={
                      <Avatar
                        src={COMPANIES.IMAGES[item]}
                        style={{ margin: 5, width: 25, height: 25 }}
                      />
                    }
                    key={item}
                    tabIndex={-1}
                    label={COMPANIES.NAMES[item]}
                    className={classes.chip}
                    onDelete={this.handleDelete(item)}
                    variant="outlined"
                  />
                )),
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown,
                placeholder:
                  'Pick 1~8 companies you want referrals to',
              }),
              label: 'Desired companies',
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(this.props.authUser, inputValue2).map(
                  (suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({
                        item: suggestion.key,
                      }),
                      highlightedIndex,
                      selectedItem: selectedItem2,
                    }),
                )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

MultiSelectInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

function CompanyAutoCompleteField(props) {
  const { classes, onCompaniesChange, error } = props;

  return (
    <div className={classes.root}>
      <MultiSelectInput
        authUser={props.authUser}
        classes={classes}
        error={error}
        onCompaniesChange={onCompaniesChange}
      />
    </div>
  );
}

CompanyAutoCompleteField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompanyAutoCompleteField);
