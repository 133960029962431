import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    minWidth: 275,
    maxWidth: 600,
    marginBottom: '15px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    marginTop: 30,
    marginBottom: 30,
    fullWidth: true,
  },
});

class FAQPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingTop: '20px' }}>
        <Card className={classes.card}>
            <CardHeader
            title='How does a referral help?'
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                Referrals will greatly increase your chances of landing an interview.
                We found that your relationship with the referrer did not matter.
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title='Is my identity safe on this site?'
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                <b>Your profile is only visible to your potential referrers.</b> Ex. if you request for Google
                referrals, only verified Google employees can see your profile information.
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title='Do people really get referrals?'
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                Yes! Historically, around 70% of candidates get referrals. 99% of candidates
                get some amount of consideration (profile click, referral button click).
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title="How do I get more referrals?"
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                Referrers tend to reach out to people that are "relatable". Ex. same job function,
                company/school alumni, or same hometown. Candidates with
                verbose/hyper-detailed job titles tend to get the least amount of attention.
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title="What's your business model?"
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                There is no business model, this site is not-for-profit.
                We appreciate donations though!
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title="Why would I refer strangers?"
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                We believe engineers can identify good engineers, better than sourcers can (who do this as their job).
                This is your chance to help out candidates who are new to the industry, and to hire great co-workers.
                We know you'll get a referral bonus, but please don't abuse your company's system.
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title="Doesn't this ruin the integrity of referrals?"
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                <b>Don't refer someone you wouldn't hire! </b>We believe the integrity of a referral is up to the referrer.
                Every referral form has a field to indicate how you know this person.
            </Typography>
            </CardContent>
        </Card>
        <Card className={classes.card}>
            <CardHeader
            title='How do I give feedback?'
            />
            <CardContent style={{ paddingTop: '5px'}}>
            <Typography variant="body1">
                Email repher.me@gmail.com
            </Typography>
            </CardContent>
        </Card>
      </div>
    );
  }
}

FAQPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQPage);
