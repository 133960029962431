import React, { Component } from 'react';

import RepherralForm from './form';

import { AuthUserContext, withAuthorization } from '../Session';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as ROUTES from '../../constants/routes';

const styles = theme => ({
  card: {
    minWidth: 275,
    maxWidth: 600,
  },
});

class CreateRepherralPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div style={{ paddingTop: '20px' }}>
            <Card className={classes.card}>
              <CardContent>
                <RepherralForm authUser={authUser} />
              </CardContent>
            </Card>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

CreateRepherralPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withAuthorization(true)(
  withStyles(styles)(CreateRepherralPage),
);
