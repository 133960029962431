import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Repherrals from '../Repherrals';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class PreviewModalWrapped extends React.Component {
  handleClick = () => {
    this.props.handleClose(true);
  };

  handleClose = () => {
    this.props.handleClose(false);
  };

  render() {
    return (
      <div>
        <Dialog
          fullWidth
          disableEnforceFocus
          open={this.props.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent style={{ padding: '0px' }}>
            <Repherrals
              showPreview
              repherral={this.props.repherral}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              style={{ marginBottom: '10px' }}
              variant="contained"
              onClick={this.handleClose}
              color="primary"
            >
              Go back
            </Button>
            <Button
              style={{ marginBottom: '10px' }}
              variant="contained"
              onClick={this.handleClick}
              color="secondary"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default PreviewModalWrapped;
