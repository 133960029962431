import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import Repherrals from '../Repherrals';

class HomePage extends Component {
  componentDidMount() {}

  render() {
    return (
      <div style={{ paddingTop: '20px'}}>
        <Repherrals showList />
      </div>
    );
  }
}

export default compose(withFirebase)(HomePage);
