import React, { PureComponent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withAuthentication } from '../Session';
import * as COMPANIES from '../../constants/companies';

class RepherralListFilter extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
          query: '',
          yoe: false,
          verified: false,
          refer: false,
        };
    }

    canRefer() {
        return !!this.props.authUser && (this.props.authUser.domain in COMPANIES.KEYS);
    }

    shouldRefer() {
        return !!this.props.authUser && (this.props.authUser.domain in COMPANIES.DESTS);
    }

	componentWillMount() {
		this.props.firebase
		.filterQuery(this.props.authUser.uid)
		.once('value', snapshot => {
            const filterObj = snapshot.val();
            // Query and shouldRefer takes on default values on refresh.
            let newFilter = {
                query: '',
                yoe: false,
                verified: false,
                refer: this.shouldRefer(),
            };

            if (filterObj) {
                newFilter = {
                    query: '',
                    yoe: filterObj.yoe,
                    verified: filterObj.verified,
                    refer: this.shouldRefer(),
                };
            }

            this.setState(newFilter);
            this.props.onChange(newFilter);
        });
	}

	componentWillUnmount() {
		this.props.firebase.user().off();
	}

    handleYoeChange = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
        this.props.onChange(
            {
                query: this.state.query,
                yoe: event.target.checked,
                verified: this.state.verified,
                refer: this.state.refer,
            }
        );
    }

    handleVerifiedChange = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
        this.props.onChange(
            {
                query: this.state.query,
                yoe: this.state.yoe,
                verified: event.target.checked,
                refer: this.state.refer,
            }
        );
    }

    handleReferChange = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
        this.props.onChange(
            {
                query: this.state.query,
                yoe: this.state.yoe,
                verified: this.state.verified,
                refer: event.target.checked,
            }
        );
    }

    handleFilterChange = (event) => {
        this.setState({ query: event.target.value });
        this.props.onChange(
            {
                query: event.target.value,
                yoe: this.state.yoe,
                verified: this.state.verified,
                refer: this.state.refer,
            }
        );
    }

    render() {
        return (
            <div style={{ width: '100%', display: 'block', marginTop:'-15px', marginBottom: '15px' }}>
                <div style={{ float:'left', marginRight: '10px', width: '100%'}}>
                    <TextField
                        fullWidth
                        name="query"
                        label="Filter"
                        placeholder='Filter by current role, location, or school'
                        margin="normal"
                        value={this.state.query}
                        onChange={this.handleFilterChange}
                        type="search"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div style={{ width: '100%' }}>
                        {this.canRefer() && (
                        <FormControlLabel
                            control={
                            <Checkbox checked={this.state.refer} onChange={this.handleReferChange('refer')} value="refer" />
                            }
                            label={COMPANIES.NAMES[this.props.authUser.domain] + ' candidates'}
                        />
                        )}
                        <FormControlLabel
                            control={
                            <Checkbox checked={this.state.verified} color="primary" onChange={this.handleVerifiedChange('verified')} value="verified" />
                            }
                            label="Verified"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox checked={this.state.yoe} color="primary" onChange={this.handleYoeChange('yoe')} value="yoe" />
                            }
                            label="Experienced"
                        />
                </div>
            </div>
    );
  }
}

export default withAuthentication(RepherralListFilter);
