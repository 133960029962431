import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { withFirebase } from '../Firebase';
import * as COMPANIES from '../../constants/companies';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    marginTop: 30,
    fullWidth: true,
  },
});

const INITIAL_STATE = {
  email: '',
  password: '',
  isReferrer: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists.
  Try to login with this account instead.
`;

class RepherreeFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  onSubmit = event => {
    const { email, password, isReferrer } = this.state;

    var domain = email.replace(/.*@/, '');
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.user(authUser.user.uid).set({
          domain:
            domain in COMPANIES.KEYS
              ? COMPANIES.KEYS[domain]
              : domain,
          email,
          isReferrer,
        });
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { email, password, error } = this.state;
    const { classes } = this.props;

    return (
      <form className={classes.container} onSubmit={this.onSubmit}>
        {error && <p style={{ color: 'red' }}>{error.message}</p>}
        <TextField
          className={classNames(classes.margin, classes.textField)}
          fullWidth
          error={!!error}
          name="email"
          label="Email (Work Email Recommended)"
          placeholder="you@yourcompany.com"
          margin="normal"
          variant="outlined"
          value={email}
          type="email"
          onChange={this.onChange}
          helperText="Use your work email for higher credibility & security. Your profile is only visible to potential referrers."
        />
        <TextField
          className={classNames(classes.margin, classes.textField)}
          fullWidth
          error={!!error}
          variant="outlined"
          name="password"
          type="password"
          margin="normal"
          label="Password"
          value={password}
          onChange={this.onChange}
        />
        <Button
          className={classNames(classes.button)}
          variant="contained"
          fullWidth
          color="primary"
          type="submit"
        >
          Register
        </Button>
      </form>
    );
  }
}

const RepherreeForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles),
)(RepherreeFormBase);

RepherreeFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default RepherreeForm;
