import React, { Component } from 'react';
import { AuthUserContext, withAuthentication } from '../Session';
import MyRepherralList from './MyRepherralList';
import RepherralList from './RepherralList';
import RepherralDetail from './RepherralDetail';
import RepherralPreview from './RepherralPreview';
import CompanyList from './CompanyList';
import CompanyListNonAuth from './CompanyListNonAuth';
import HomeList from './HomeList';

class Repherrals extends Component {
  onRemoveRepherral = repherral => {
    // Delete from repherrals.
    this.props.firebase.repherral(repherral.id).remove();

    // Delete metadata.
    this.props.firebase
      .repherralMetadata(repherral.metadataId)
      .remove();
  };

  render() {
    if (this.props.showList) {
      return (
        <AuthUserContext.Consumer>
          {authUser => (
            authUser !== null ?
              (<RepherralList {...this.props} authUser={authUser} onRemoveRepherral={this.onRemoveRepherral} />)
            : (<HomeList {...this.props} authUser={authUser} onRemoveRepherral={this.onRemoveRepherral} />)
          )}
        </AuthUserContext.Consumer>
      );
    }

    if (this.props.showCompanyList) {
      return (
        <AuthUserContext.Consumer>
          {authUser => (
            authUser !== null ?
              (<CompanyList
                company={this.props.company}
                onRemoveRepherral={this.onRemoveRepherral}
              />)
            : (<CompanyListNonAuth
              company={this.props.company}
              onRemoveRepherral={this.onRemoveRepherral}
            />)
          )}
        </AuthUserContext.Consumer>
      );
    }

    if (this.props.showPreview) {
      return (
        <RepherralPreview
          repherral={this.props.repherral}
        />
      );
    }

    if (this.props.showDetails) {
      return (
        <RepherralDetail
          id={this.props.id}
          onRemoveRepherral={this.onRemoveRepherral}
        />
      );
    }

    if (this.props.showMyList) {
      return (
        <AuthUserContext.Consumer>
          {authUser => (
            <MyRepherralList
              onRemoveRepherral={this.onRemoveRepherral}
              authUser={authUser}
            />
          )}
        </AuthUserContext.Consumer>
      );
    }

    return <div />;
  }
}

export default withAuthentication(Repherrals);
