import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RepherModalWrapped from './RepherModal';
import { withFirebase } from '../Firebase';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Tooltip from '@material-ui/core/Tooltip';
import * as COMPANIES from '../../constants/companies';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdviceModal from './AdviceModal';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';

const styles = theme => ({
  card: {
    maxWidth: 600,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  bigAvatar: {
    margin: '6.5px',
    width: 50,
    height: 50,
  },
});

class RepherralItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      repherralMetadata: null,
      snackbarShowing: false,
      anchorEl: null,
      openModal: false,
      openAuthUserDialog: false,
      expanded: false,
    };
  }

  onClickReferButton = () => {
    // Non-auth user click should go to registration.
    if (!this.props.authUser) {
      this.props.history.push({
        pathname: ROUTES.REGISTER,
        state: { registrationType: 'repherrer' },
      });
      return;
    }

    if (this.isAuthUsersReferral()) {
      this.setState({ openAuthUserDialog: true });
      return;
    }

    if (this.canRefer()) {
      if (!this.props.authUser.emailVerified) {
        this.setState({
          snackbarShowing: true,
          snackbarMessage: 'Verify your email to refer!',
        });
        return;
      }

      this.props.firebase
        .referralInterest(
          this.props.repherral.userId,
          this.props.authUser.uid,
        )
        .set({
          referrer_id: this.props.authUser.uid,
          referrer_domain: this.props.authUser.domain,
          click_timestamp: Date.now(),
        });
      this.setState({ openModal: true });
    }
  };

  onShareClick = () => {
    copy('https://repher.me/r/' + this.props.repherral.id);
    this.setState({
      snackbarShowing: true,
      snackbarMessage: 'Copied URL to clipboard',
    });
  };

  onExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  onCompanyClick = company => {
    this.props.history.push({
      pathname: '/to/' + company,
    });
  };

  handleSnackbarClose = () => {
    this.setState({ snackbarShowing: false });
  };

  handleOpenMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.props.onRemoveRepherral(this.props.repherral);
  };

  handleCloseModal = (did_refer, candidate_email, referrer_email) => {
    if (did_refer) {
      this.props.authUser.alternate_email = referrer_email;

      this.props.firebase
      .userRepherrals(
        this.props.authUser.uid,
        this.props.repherral.userId,
      )
      .update({
        did_refer: true,
        timestamp: Date.now(),
        referral_id: this.props.repherral.id,
        referrer_domain: this.props.authUser.domain,
        candidate_email: candidate_email,
        referrer_email: referrer_email,
        company: COMPANIES.NAMES[this.props.authUser.domain],
      });

      this.props.firebase
      .user(
        this.props.authUser.uid
      )
      .update({
        alternate_email: referrer_email,
      });
    }

    this.setState({
      openModal: false,
      snackbarShowing: did_refer,
      snackbarMessage: "We connected you with the candidate. Check your inbox!",
    });
  };

  canRefer = () => {
    return (
      !this.isAuthUsersReferral() &&
      this.props.authUser &&
      this.props.repherral &&
      this.props.repherral.companies[this.props.authUser.domain]
    );
  };

  showRefer = () => {
    return !this.props.authUser || this.canRefer();
  };

  getReferMessage() {
    if (!this.props.authUser) {
      return 'Reveal Candidate';
    }
    if (this.isAuthUsersReferral()) {
      return 'Promote Referral';
    }
    return 'Refer Candidate';
  }

  isAuthUsersReferral = () => {
    return (
      this.props.authUser &&
      this.props.repherral.userId === this.props.authUser.uid
    );
  };

  componentDidMount() {
    this.updateRepherralMetadata();
  }

  componentDidUpdate() {
    if (!this.state.repherralMetadata) {
      this.updateRepherralMetadata();
    }
  }

  componentWillUnmount() {
    this.props.firebase.repherralMetadata().off();
  }

  updateRepherralMetadata = () => {
    // Only fetch metadata for potential referrers or self.
    const canFetchMetadata =
      !this.props.isPreview &&
      (this.canRefer() || this.isAuthUsersReferral());
    if (!canFetchMetadata) {
      return;
    }

    this.props.firebase
      .repherralMetadata(this.props.repherral.metadataId)
      .on('value', snapshot => {
        const repherralMetadataObj = snapshot.val();
        if (repherralMetadataObj) {
          this.setState({
            repherralMetadata: repherralMetadataObj,
          });
        }
      });
  };

  getVerifiedUserIcon = () => {
    return (
      <div style={{ display: 'inline-block' }}>
        <Tooltip
          open={this.props.isFake}
          placement="bottom"
          title={'Verified ' + this.props.repherral.role + ' at ' + this.props.repherral.prevExp}
        >
          <VerifiedUserIcon
            style={{
              position: 'relative',
              top: '2px',
              marginLeft: '5px',
            }}
            color="primary"
          />
        </Tooltip>
      </div>
    );
  };

  getTitleDiv = () => {
    var title =
      this.props.repherral.prevExp !== '' ? (
        this.props.repherral.role +
        ' at ' +
        this.props.repherral.prevExp
      ) : (
        <span>
          {this.props.repherral.role + ' at  '}
          <span
            style={{
              color: 'transparent',
              textShadow: '0 0 30px rgba(0,0,0,0.5)',
            }}
          >
            {' COMPANY'}
          </span>
        </span>
      );
    return (
      <div>
        {this.isAuthUsersReferral() && !this.props.isPreview && (
          <Tooltip title="This is your referral request as presented to potential referrers.">
            <PersonIcon
              style={{
                position: 'relative',
                top: '4px',
                marginRight: '5px',
              }}
              color="secondary"
            />
          </Tooltip>
        )}
        {title}
        {this.props.repherral.isVerifiedWorkplace &&
          this.getVerifiedUserIcon()}
      </div>
    );
  };

  hasProfiles = () => {
    if (!this.state.repherralMetadata) {
      return false;
    }

    return this.props.repherral.hasLink || this.props.repherral.hasGithub || this.props.repherral.hasLeetcode;
  };

  getProfiles = () => {
    let profiles = [];
    if (this.props.repherral.hasLink) {
      profiles.push({
        name: 'LinkedIn',
        link: this.state.repherralMetadata.link
      });
    }
    if (this.props.repherral.hasResume) {
      profiles.push({
        name: 'Resume',
        link: this.state.repherralMetadata.resume
      });
    }
    if (this.props.repherral.hasGithub) {
      profiles.push({
        name: 'GitHub',
        link: this.state.repherralMetadata.github
      });
    }
    if (this.props.repherral.hasLeetcode) {
      profiles.push({
        name: 'Leetcode',
        link: this.state.repherralMetadata.leetcode
      });
    }

    return (
      <div style={{ display: 'inline-block' }}>
        {profiles.map((profile, index) => (
          <div style={{ display: 'inline' }}>
            <a
              href={profile.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' ' + profile.name + ' '}
            </a>
            {index < profiles.length - 1 && (' | ')}
          </div>
        ))}
      </div>
    );
  }

  getSubHeaderDiv = () => {
    return (
      <div>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          Current Location: {this.props.repherral.location}
        </div>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          Years of Experience: {this.props.repherral.yoe}
        </div>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          School:{' '}
          {this.props.repherral.school !== '' ? (
            this.props.repherral.school
          ) : (
            <div
              style={{
                display: 'inline',
                color: 'transparent',
                textShadow: '0 0 10px rgba(0,0,0,0.5)',
              }}
            >
              {' NoneOfYour School of Business '}
            </div>
          )}
        </div>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          {this.isAuthUsersReferral() ? ('Profiles 🔒: ') : ('Profiles: ')}
          {this.hasProfiles() ? (
            this.getProfiles()
          ) : (
            <div
              style={{
                display: 'inline',
                color: 'transparent',
                textShadow: '0 0 10px rgba(0,0,0,0.5)',
              }}
            >
              {' https://linkedin.com/in/stopnosing '}
            </div>
          )}
        </div>
      </div>
    );
  };

  getDesiredCompany = key => {
    const { classes } = this.props;

    return (
      <Tooltip
       title={COMPANIES.NAMES[key]}
      >
        <IconButton
          key={'icon-' + key}
          style={{ padding: 0 }}
          onClick={() => this.onCompanyClick(key)}
        >
          <Avatar
            style={
              this.props.authUser && this.props.authUser.domain === key
                ? {
                    border: '4px solid transparent',
                    backgroundImage:
                      'linear-gradient(white, white), linear-gradient(to right, red, orange)',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'content-box, border-box',
                  }
                : { border: '4px solid transparent' }
            }
            key={key}
            src={COMPANIES.IMAGES[key]}
            className={classes.bigAvatar}
          />
        </IconButton>
      </Tooltip>
    );
  };

  getDesiredCompanies = () => {
    return (
      <div>
        <Grid container justify="center" alignItems="center">
          <Typography variant="overline">
            Looking for referrals to
          </Typography>
        </Grid>
        <Grid container justify="center" alignItems="center">
          {Object.keys(this.props.repherral.companies).map(key =>
            this.getDesiredCompany(key),
          )}
        </Grid>
      </div>
    );
  };

  getReferButton = () => {
    const { classes } = this.props;
    const button = (
      <Button
        style={this.isAuthUsersReferral() ? {
          backgroundColor: "#4caf50",
        } : {}}
        fullWidth
        disabled={!this.isAuthUsersReferral() && !this.showRefer()}
        className={classes.button}
        variant="contained"
        color={this.showRefer() ? 'secondary' : 'primary'}
        onClick={this.onClickReferButton}
      >
        {this.getReferMessage()}
      </Button>
    );
    return button;
  };

  getCard = () => {
    const { classes } = this.props;
    const openMenuBar = Boolean(this.state.anchorEl);
    return (
      <Card
        className={classes.card}
        raised={this.isAuthUsersReferral()}
      >
        <CardHeader
          style={{ paddingBottom: '0px' }}
          action={
            this.isAuthUsersReferral() &&
            !this.props.isPreview && (
              <div>
                <IconButton
                  aria-owns={openMenuBar ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleOpenMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openMenuBar}
                  onClose={this.handleCloseMenu}
                >
                  <MenuItem onClick={this.handleDelete}>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            )
          }
          title={this.getTitleDiv()}
          subheader={this.getSubHeaderDiv()}
        />

        <CardContent
          style={{ paddingTop: '0px', paddingBottom: '5px' }}
        >
          {this.getDesiredCompanies()}
        </CardContent>
        {this.getCardActions()}
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ paddingTop: '5px'}}>
          <Typography variant="overline" style={{ marginBottom: '10px' }}>
            Message from the candidate
          </Typography>
          <Typography variant="body1">
            {this.state.repherralMetadata ? this.state.repherralMetadata.bio : ''}
          </Typography>
        </CardContent>
      </Collapse>
      </Card>
    );
  };

  getCardActions = () => {
    if (this.props.isPreview) {
      return;
    }

    const { classes } = this.props;
    return (
      <CardActions className={classes.actions} disableActionSpacing>
      <IconButton
        style={{ marginRight: 'auto' }}
        onClick={this.onShareClick}
      >
        <ShareIcon />
      </IconButton>
      <div
        style={{
          flexGrow: 1,
          minWidth: '100px',
          maxWidth: '200px',
        }}
      >
        {this.getReferButton()}
      </div>
      <IconButton
        style={{
          marginLeft: 'auto',
          visibility: this.state.repherralMetadata && this.props.repherral.hasBio ? 'visible' : 'hidden'
        }}
        className={clsx(classes.expand, {
          [classes.expandOpen]: this.state.expanded,
        })}
        onClick={this.onExpandClick}
        aria-expanded={this.state.expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </IconButton>
    </CardActions>
    );
  }

  handleAuthUserDialogClose = (did_click) => {
    this.setState({ openAuthUserDialog: false });
  }

  getAuthUserDialog() {
    return (
      <AdviceModal open={this.state.openAuthUserDialog} onClose={this.handleAuthUserDialogClose} />
    );
  };

  render() {
    return (
      <div>
        {this.getCard()}
        {this.getAuthUserDialog()}
        <RepherModalWrapped
          open={this.state.openModal}
          handleClose={this.handleCloseModal}
          repherral={this.props.repherral}
          authUser={this.props.authUser}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarShowing}
          onClose={this.handleSnackbarClose}
          autoHideDuration={5000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span style={{ fontSize: '16px' }} id="message-id">{this.state.snackbarMessage}</span>
          }
        />
      </div>
    );
  }
}

RepherralItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withFirebase(
  withRouter(withStyles(styles)(RepherralItem)),
);
