import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withFirebase } from '../Firebase';
import TextField from '@material-ui/core/TextField';

class RepherModalWrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      candidate_email: '',
      alternate_email: this.props.authUser ? this.getAuthUserEmail() : '',
      alternate_email_error: false,
      already_referred: 0, // 0: unknown, 1: did not refer, 2: did refer.
    };
  }

  getAuthUserEmail() {
    return this.props.authUser.alternate_email || this.props.authUser.email;
  }

  handleClick = () => {
    if (!this.isValidEmail(this.state.alternate_email)) {
      this.setState({ alternate_email_error: true });
      return;
    }

    this.props.handleClose(true, this.state.candidate_email, this.state.alternate_email);
  };

  isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleClose = () => {
    this.props.handleClose(false);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.state.candidate_email === '') {
        this.props.firebase
        .user(this.props.repherral.userId)
        .once('value', snapshot => {
          const userObj = snapshot.val();
          if (userObj) {
            this.setState({
              candidate_email: userObj.alternate_email || userObj.email,
            });
          }
        });
      }

      this.props.firebase
      .userRepherrals(
        this.props.authUser.uid,
        this.props.repherral.userId,
      )
      .once('value', snapshot => {
        const repherral = snapshot.val();
        if (repherral) {
          this.setState({
            already_referred: 2,
          });
        } else {
          this.setState({
            already_referred: 1,
          });
        }
      });

      if (this.state.alternate_email !== this.props.authUser.alternate_email) {
        this.setState({alternate_email: this.getAuthUserEmail() });
      }
    }
  }

  componentWillUnmount() {
    this.props.firebase.user().off();
  }

  render() {
    return (
      <Dialog
      open={this.props.open}
      onClose={this.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            {'🙏 Thank you stranger!\n... What happens now?'}
          </DialogTitle>
          <DialogContent>
            <p>
              1. Review and update your alternate email below.
            </p>
            <TextField
              error={this.state.alternate_email_error}
              fullWidth
              name="alternate_email"
              label="Alternate Email"
              placeholder="me@gmail.com"
              margin="normal"
              variant="outlined"
              value={this.state.alternate_email}
              onChange={this.onChange}
              type="email"
            />
            <p>
              2. We will send an email connecting you and the candidate (
              <a id={this.props.userId} href={'mailto:' + this.state.candidate_email}>
                {this.state.candidate_email}
              </a>
              {')'}
            </p>
            <p>
              3. Follow the referral process of your company!
            </p>
            <p style={{ textAlign: 'center', fontSize: '10px'}}>
                Please be responsible and don't refer someone you wouldn't hire.
            </p>
              <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                style={{ marginTop: '15px' }}
                variant="contained"
                onClick={this.handleClick}
                color={(this.state.candidate_email === '' || this.state.already_referred === 2) ? 'inherit' : 'secondary'}
              >
                {this.state.already_referred === 2 ? 'CHECK YOUR INBOX' : 'CONNECT ME'}
              </Button>
            </DialogActions>
          </DialogContent>
      </Dialog>
    );
  }
}

export default withFirebase(RepherModalWrapped);
