import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import ReactDOM from 'react-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { withFirebase } from '../Firebase';

import CompanyAutoCompleteField from '../CompanyAutocomplete';
import PreviewModalWrapped from './PreviewModal';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import * as ROUTES from '../../constants/routes';
import * as COMPANIES from '../../constants/companies';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing.unit,
  },
  button: {
    marginTop: 30,
    fullWidth: true,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const INITIAL_STATE = {
  companies: [],
  companiesError: false,
  role: '',
  roleError: false,
  location: '',
  locationError: false,
  yoe: '',
  yoeError: false,
  prevExp: '',
  prevExpError: false,
  school: '',
  link: '',
  linkError: false,
  resume: '',
  resumeError: false,
  github: '',
  githubError: false,
  leetcode: '',
  leetcodeError: false,
  bio: '',
  openModal: false,
};

class RepherralFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, labelWidth: 0 };

    if (this.props.authUser) {
      if (COMPANIES.NAMES[this.props.authUser.domain]) {
        this.state.prevExp =
          COMPANIES.NAMES[this.props.authUser.domain];
      }
    }
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef)
        .offsetWidth,
    });
  }

  validURL = url => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ); // fragment locator
    var isValidUrl = !!pattern.test(url);
    if (!isValidUrl) {
      return false;
    }
    if (url.indexOf("https://") !== 0) {
      return false;
    }
    // If it's a linkedIn, url, make sure they don't forget /in.
    if (url.includes('linkedin') && !url.includes('/in/')) {
      return false;
    }

    return true;
  };

  validateForm = () => {
    const { companies, role, location, yoe, prevExp, link, resume, github, leetcode } = this.state;
    this.setState({
      companiesError: companies.length === 0,
      roleError: role === '',
      locationError: location === '',
      yoeError: yoe === '',
      prevExpError: prevExp === '',
      linkError: link === '' || !link.includes('linkedin') || !this.validURL(link),
      resumeError: resume !== '' && !this.validURL(resume),
      githubError: github !== '' && (!github.includes('github') || !this.validURL(github)),
      leetcodeError: leetcode !== '' && (!leetcode.includes('leetcode') || !this.validURL(leetcode)),
    });

    if (
      companies.length === 0 ||
      role === '' ||
      location === '' ||
      yoe === '' ||
      prevExp === '' ||
      (link === '' || !link.includes('linkedin') || !this.validURL(link)) ||
      (resume !== '' && !this.validURL(resume)) ||
      (github !== '' && (!github.includes('github') || !this.validURL(github))) ||
      (leetcode !== '' && (!leetcode.includes('leetcode') || !this.validURL(leetcode)))
    ) {
      return false;
    }

    return true;
  };

  onSubmit = event => {
    const {
      companies,
      role,
      location,
      yoe,
      prevExp,
      school,
      link,
      resume,
      github,
      leetcode,
      bio,
    } = this.state;

    if (!this.validateForm()) {
      return;
    }

    // 1. Create the referral object.
    // 2. Add the metadata separately.
    // 3. Add the repherral key to each company's list.
    // 2 & 3 can be done concurrently, and it would be ideal to do in a single txn.
    const timestamp = this.props.firebase.serverValue.TIMESTAMP;
    const companiesMap = companies.reduce(function(map, obj) {
      map[obj] = true;
      return map;
    }, {});

    this.props.firebase
      .repherralMetadatas()
      .push({
        companies: companiesMap,
        link,
        resume,
        github,
        leetcode,
        bio,
        userId: this.props.authUser.uid,
      })
      .then(metadata => {
        return this.props.firebase.repherrals().push({
          metadataId: metadata.key,
          userId: this.props.authUser.uid,
          companies: companiesMap,
          role,
          location,
          yoe,
          prevExp,
          school,
          isVerifiedWorkplace:
            prevExp === COMPANIES.NAMES[this.props.authUser.domain],
          hasLink: link !== '',
          hasResume: resume !== '',
          hasGithub: github !== '',
          hasLeetcode: leetcode !== '',
          hasBio: bio !== '',
          createdAt: timestamp,
        });
      })
      .then((referral) => {
        // TODO: make this go to detail page.
        this.setState({ ...INITIAL_STATE });
        this.props.history.push({
          pathname: ROUTES.HOME,
          state: { didCreate: true, referralId: referral.key, }
        });
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onCompaniesChange = event => {
    this.setState({
      companies: event,
    });
  };

  onPreview = event => {
    if (!this.validateForm()) {
      event.preventDefault();
      return;
    }

    this.setState({ openModal: true });
  };

  getPreviewRepherral = () => {
    const {
      companies,
      role,
      location,
      yoe,
      prevExp,
      school,
    } = this.state;

    const companiesMap = companies.reduce(function(map, obj) {
      map[obj] = true;
      return map;
    }, {});

    return {
      userId: this.props.authUser.uid,
      companies: companiesMap,
      role,
      location,
      yoe,
      prevExp,
      school,
      isVerifiedWorkplace:
        prevExp === COMPANIES.NAMES[this.props.authUser.domain],
    };
  };

  handleClose = didSubmit => {
    if (!didSubmit) {
      this.setState({
        openModal: false,
      });
      return;
    }

    this.onSubmit();
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <form className={classes.container} onSubmit={this.onSubmit}>
          <CompanyAutoCompleteField
            authUser={this.props.authUser}
            error={this.state.companiesError}
            onCompaniesChange={this.onCompaniesChange.bind(this)}
          />
          <TextField
            error={this.state.roleError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="role"
            label="Current Role"
            placeholder="Software Engineer (don't put desired role)"
            margin="normal"
            variant="outlined"
            value={this.state.role}
            onChange={this.onChange}
            helperText="You can express the desired role to your referrer."
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            error={this.state.prevExpError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="prevExp"
            label="Current/Last Employment or School"
            placeholder="Your current/latest employer, or school if you're a student"
            margin="normal"
            variant="outlined"
            value={this.state.prevExp}
            onChange={this.onChange}
            inputProps={{
              maxLength: 30,
            }}
          />
          <TextField
            error={this.state.locationError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="location"
            label="Current Location"
            placeholder="San Francisco (don't put desired location)"
            margin="normal"
            variant="outlined"
            value={this.state.location}
            onChange={this.onChange}
            helperText="You can express the desired location to your referrer."
            inputProps={{
              maxLength: 30,
            }}
          />
          <FormControl
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel
              ref={ref => {
                this.InputLabelRef = ref;
              }}
              htmlFor="outlined-age-simple"
            >
              Years of Experience
            </InputLabel>
            <Select
              value={this.state.yoe}
              onChange={this.onChange}
              input={
                <OutlinedInput
                  error={this.state.yoeError}
                  labelWidth={this.state.labelWidth}
                  name="yoe"
                  id="outlined-age-simple"
                />
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Intern'}>Intern</MenuItem>
              <MenuItem value={'New Grad'}>New Grad</MenuItem>
              <MenuItem value={'1-3'}>1-3</MenuItem>
              <MenuItem value={'4-6'}>4-6</MenuItem>
              <MenuItem value={'7-10'}>7-10</MenuItem>
              <MenuItem value={'11-15'}>11-15</MenuItem>
              <MenuItem value={'15+'}>15+</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="school"
            label="School (for the alumni network boost!)"
            placeholder="Stanford"
            margin="normal"
            variant="outlined"
            value={this.state.school}
            onChange={this.onChange}
          />
          <TextField
            error={this.state.linkError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="link"
            label="LinkedIn URL"
            placeholder="https://www.linkedin.com/in/username"
            margin="normal"
            variant="outlined"
            value={this.state.link}
            onChange={this.onChange}
            helperText="🔒This is only visible to potential referrers."
          />
          <TextField
            error={this.state.resumeError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="resume"
            label="Resume URL (Optional)"
            margin="normal"
            variant="outlined"
            value={this.state.resume}
            onChange={this.onChange}
            helperText="🔒This is only visible to potential referrers."
          />
          <TextField
            error={this.state.githubError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="github"
            label="Github URL (Optional)"
            placeholder="https://www.github.com/username"
            margin="normal"
            variant="outlined"
            value={this.state.github}
            onChange={this.onChange}
            helperText="🔒This is only visible to potential referrers."
          />
          <TextField
            error={this.state.leetcodeError}
            className={classNames(classes.margin, classes.textField)}
            fullWidth
            name="leetcode"
            label="Leetcode URL (Optional)"
            placeholder="https://www.leetcode.com/username"
            margin="normal"
            variant="outlined"
            value={this.state.leetcode}
            onChange={this.onChange}
            helperText="🔒This is only visible to potential referrers."
          />
          <TextField
            fullWidth
            className={classNames(classes.margin, classes.textField)}
            name="bio"
            label="Your message to potential referrers"
            placeholder="Tell us anything that stands out. 300+ LC? Laid-off? App with 10k users? Creativity goes a long way here!"
            multiline
            rows="3"
            rowsMax="4"
            margin="normal"
            variant="outlined"
            helperText="🔒This is only visible to potential referrers."
            onChange={this.onChange}
            value={this.state.bio}
            inputProps={{
              maxLength: 160,
            }}
          />
          <Button
            className={classNames(classes.button)}
            variant="contained"
            fullWidth
            color="primary"
            onClick={this.onPreview}
            disabled={!this.props.authUser.emailVerified}
          >
            {this.props.authUser.emailVerified
              ? 'Preview'
              : 'Verify your email first'}
          </Button>
        </form>
        <PreviewModalWrapped
          open={this.state.openModal}
          handleClose={this.handleClose}
          repherral={this.getPreviewRepherral()}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

RepherralFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const RepherralForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles),
)(RepherralFormBase);

export default RepherralForm;
