import React, { Component } from 'react';

import { AuthUserContext, withAuthentication } from '../Session';
import AdviceModal from './AdviceModal';
import RepherralItem from './RepherralItem';
import RepherralListFilter from './RepherralListFilter';
import { withRouter } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { debounce } from 'lodash';

class RepherralList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: !!this.props.location && !!this.props.location.state && this.props.location.state.didCreate,
      showCreateMessage: this.props.location && this.props.location.state && this.props.location.state.didCreate,
      createdReferralId: this.props.location && this.props.location.state ? this.props.location.state.referralId : '',
      loading: true,
      repherrals: [],
      visibleCount: 0,
      reachedEnd: false,
    };

    this.resetStates();
  }

  resetStates() {
    this.limit = 5;
    this.capacity = 215;
    this.fetching = false;
  }

  componentWillMount() {
    this.listener = this.props.firebase.onAuthUserListener(
      authUser => {
        this.resetStates();
        this.setState({
          reachedEnd: false,
          repherrals: [],
          visibleCount: 0,
        });
      },
      () => {
        this.resetStates();
        this.filters = null;
        this.setState({
          reachedEnd: false,
          repherrals: [],
          filterQuery: null,
          visibleCount: 0,
        },
        this.onListenForRepherrals());
      },
    );
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
    this.props.history.replace('', null);
  }

  componentWillUnmount() {
    this.props.firebase.repherrals().off();
    this.listener();
    document.removeEventListener('scroll', this.trackScrolling);
  }

  onListenForRepherrals = () => {
    if (this.state.reachedEnd) {
      return;
    }
    let limit = this.limit;
    let prevVisibleCount = this.state.visibleCount;
    this.fetching = true;
    this.setState({ loading: true });

    if (this.filters && this.filters.refer && this.props.authUser) {
      this.props.firebase
      .repherrals()
      .orderByChild('companies/' + this.props.authUser.domain)
      .equalTo(true)
      .limitToLast(limit)
      .on('value', snapshot => {
        this.processServerResponse(snapshot, limit, prevVisibleCount);
      });
    } else {
      this.props.firebase
      .repherrals()
      .limitToLast(limit)
      .on('value', snapshot => {
        this.processServerResponse(snapshot, limit, prevVisibleCount);
      });
    }

    this.fetching = false;
  };

  processServerResponse(snapshot, limitFetched, prevVisibleCount) {
    const repherralObject = snapshot.val();
    if (repherralObject) {
      // Filter works by adding a display field.
      let repherrals = [];
      let visibleCount = 0;
      for (const key of Object.keys(repherralObject)) {
        let repherral = {
          ...repherralObject[key],
          id: key,
          visible: this.isIncluded(repherralObject[key]),
        };
        if (repherral.visible) {
          visibleCount++;
        }
        repherrals.push(repherral);
      }
      repherrals.reverse();

      let reachedEnd = repherrals.length < limitFetched || repherrals.length === this.capacity;
      let needsMore = !reachedEnd && (visibleCount <= prevVisibleCount || visibleCount < 3);
      this.setState({
        repherrals,
        visibleCount,
        loading: needsMore,
        reachedEnd,
      });

      // HACK: Continue paginating until we overflow the viewport. This may run into a 20 times loop.
      if (needsMore) {
        this.onNextPage();
      }
    } else {
      this.setState({
        repherrals: [],
        loading: false,
        searching: false,
        reachedEnd: true,
      });
    }
  }

  onNextPage = () => {
    this.limit = Math.min(this.limit + 10, this.capacity);
    this.onListenForRepherrals();
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom - 700 <= window.innerHeight;
  }

  trackScrolling = debounce(() => {
    const wrappedElement = document.getElementById('root');
    if (this.isBottom(wrappedElement) && !this.fetching && !this.state.reachedEnd) {
      this.onNextPage();
    }
  }, 200);

  handleCreateDialogClose = (did_click) => {
    if (did_click) {
      // Copy to clipboard.
      copy('https://repher.me/r/' + this.state.createdReferralId);
    }
    this.setState({ showCreateMessage: false });
  }

  getCreateMessageModal() {
    return (
      <AdviceModal open={this.state.showCreateMessage} onClose={this.handleCreateDialogClose} />
    );
  };

  applyFilters = () => {
    const repherrals = this.state.repherrals;
    let visibleCount = 0;
    for (const i in repherrals) {
      repherrals[i].visible = this.isIncluded(repherrals[i]);
      if (repherrals[i].visible) {
        visibleCount++;
      }
    }

    if (visibleCount < 2) {
      this.onNextPage();
      if (this.state.reachedEnd) {
        this.setState({ repherrals, visibleCount });
      }
    } else {
      this.setState({ repherrals, visibleCount });
    }
  };

  handleFilterChange = (filter) => {
    // Parse the filters.
    const space_split = filter.query.split(" ");
    const new_query = [];
    for (const i in space_split) {
      if (space_split[i].length > 2) {
        new_query.push(space_split[i]);
      }
    }

    let prevReferState = this.filters ? this.filters.refer : true;
    this.filters = {
      query: new_query,
      yoe: filter.yoe,
      verified: filter.verified,
      refer: filter.refer,
    };

    if (this.state.repherrals.length === 0 || prevReferState !== filter.refer) {
      // On referred state change, we reset everything.
      this.state.repherrals = [];
      this.limit = 5;
      this.state.reachedEnd = false;
      this.state.visibleCount = 0;
      this.onListenForRepherrals();
    } else {
      this.applyFilters();
    }

    this.props.firebase
      .filterQuery(
        this.props.authUser.uid,
      )
      .set(this.filters);
  }

  isIncluded(repherral) {
    if (!this.filters) {
      return true;
    }

    const role = repherral.role.toLowerCase();
    const prevExp = repherral.prevExp.toLowerCase();
    const location = repherral.location.toLowerCase();
    const school = repherral.school.toLowerCase();

    let queryMatches = this.filters.query.length === 0;
    for (const i in this.filters.query) {
      const q = this.filters.query[i].toLowerCase();
      if (role.includes(q)
        || prevExp.includes(q)
        || location.includes(q)
        || school.includes(q)) {
        queryMatches = true;
        break;
      }
    }

    let yoeMatches = this.filters.yoe ? (repherral.yoe !== 'Intern') : true;
    let verifiedMatches = this.filters.verified ? repherral.isVerifiedWorkplace : true;

    return queryMatches && yoeMatches && verifiedMatches;
  }

  render() {
    const { repherrals, loading } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <div style={{ minWidth: '600px' }}>
            </div>

            {/* Filter. */}
            {authUser && (
                <RepherralListFilter authUser={authUser} onChange={this.handleFilterChange} />
            )}

            {/* Filtered referral list. */}
            {repherrals && (
              <div>
                {repherrals.map(repherral => (
                  <div
                    key={repherral.id}
                    style={{
                      marginTop: '15px',
                      marginBottom: '15px',
                      display: repherral.visible ? 'block' : 'none',
                    }}
                  >
                    <RepherralItem
                      authUser={authUser}
                      repherral={repherral}
                      onRemoveRepherral={this.props.onRemoveRepherral}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* Loading spinner (doesn't spin). */}
            {loading && (
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>Loading ...</div>
            )}

            {/* Loading spinner (doesn't spin). */}
            {this.state.reachedEnd && (
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>That's all folks!</div>
            )}

            {/* Modal for successful referral creation and re-direct. */}
            {this.getCreateMessageModal()}

          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withAuthentication(withRouter(RepherralList));
