import React from 'react';
import { compose } from 'recompose';
import Repherrals from '../Repherrals';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withAuthorization } from '../Session';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class AccountPage extends React.Component {
	constructor(props) {
	  super(props);
  
	  this.state = {
		original_email: '',
		alternate_email: '',
		alternate_email_error: false,
		unsubscribed: false,
	  };
	}

	componentWillMount() {
		this.props.firebase
		.user(this.props.authUser.uid)
		.once('value', snapshot => {
			const userObj = snapshot.val();
			if (userObj) {
			this.setState({
				original_email: userObj.alternate_email || userObj.email,
				alternate_email: userObj.alternate_email || userObj.email,
				unsubscribed: userObj.unsubscribed,
			});
			}
		});
	}

	componentWillUnmount() {
		this.props.firebase.user().off();
	}

	onChange = event => {
		this.setState({
		  [event.target.name]: event.target.value,
		});
	};

	handleCheckbox = event => {
		this.setState({ unsubscribed: event.target.checked });
		this.props.firebase
		.user(
		  this.props.authUser.uid
		)
		.update({
		  unsubscribed: event.target.checked,
		}, (error) => {
			if (error) {
				console.log('Error saving preferences');
			}
		});
	};

	handleSave = () => {
		if (!this.isValidEmail(this.state.alternate_email)) {
		  this.setState({ alternate_email_error: true });
		  return;
		}

		this.props.firebase
		.user(
		  this.props.authUser.uid
		)
		.update({
		  alternate_email: this.state.alternate_email,
		}, (error) => {
			if (error) {
				console.log('Error saving email');
			} else {
				this.setState({ original_email: this.state.alternate_email });
			}
		});
	};

	isValidEmail(email) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	getAuthUserEmail() {
		return this.props.authUser.alternate_email || this.props.authUser.email;
	};

	render() {
		return (
			<div>
				<h3 style={{ fontFamily: 'Roboto Slab', textAlign: 'center' }}>Update your contact information, and see your referral requests</h3>
				<div style={{ width: '100%', display: 'inline-flex' }}>
					<div style={{ float:'left', marginRight: '10px', width: '80%'}}>
						<TextField
							error={this.state.alternate_email_error}
							fullWidth
							name="alternate_email"
							label="Contact Email"
							placeholder="me@gmail.com"
							margin="normal"
							variant="outlined"
							value={this.state.alternate_email}
							onChange={this.onChange}
							type="email"
						/>
					</div>
					<div style={{ float:'right', width: '10%', marginTop:'8px', alignSelf: 'center' }}>
						<Button
							style={{ height: '50px' }}
							variant="contained"
							onClick={this.handleSave}
							color="primary"
							disabled={this.state.original_email === this.state.alternate_email}
						>
							SAVE
						</Button>
					</div>
				</div>
				<div style={{ width: '100%', display: 'inline-flex', marginBottom: '25px' }}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.unsubscribed}
								onChange={this.handleCheckbox}
								value="unsubscribed"
								color="primary"
							/>
						}
						label="Unsubscribe from first dibs"
					/>
				</div>
				<Repherrals showMyList />
			</div>
		);
	}
};

export default compose(withAuthorization(true))(
	AccountPage,
);
