import React, { Component } from 'react';

import RepherralItem from './RepherralItem';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';

class MyRepherralList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      repherrals: [],
      limit: 10,
      reachedEnd: false,
      startScrolling: false,
    };
  }

  componentDidMount() {
    this.onListenForRepherrals();
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    this.props.firebase.repherrals().off();
    document.removeEventListener('scroll', this.trackScrolling);
  }

  onListenForRepherrals = () => {
    this.setState({ loading: true });

    const currLength = this.state.repherrals.length;
    this.props.firebase
      .repherrals()
      .orderByChild('userId')
      .equalTo(this.props.authUser.uid)
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const repherralObject = snapshot.val();
        if (repherralObject) {
          const repherralList = Object.keys(repherralObject).map(
            key => ({
              ...repherralObject[key],
              id: key,
            }),
          );
          repherralList.reverse();

          this.setState({
            repherrals: repherralList,
            loading: false,
            reachedEnd: currLength === repherralList.length,
          });
        } else {
          this.setState({ repherrals: null, loading: false });
        }
      });
  };

  onNextPage = () => {
    this.setState(
      state => ({
        limit: Math.min(state.limit + 10, 69),
        startScrolling: true,
      }),
      this.onListenForRepherrals,
    );
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this.isBottom(wrappedElement)) {
      if (this.state.reachedEnd) {
        document.removeEventListener('scroll', this.trackScrolling);
      } else if (this.state.startScrolling) {
        this.onNextPage();
      }
    }
  };

  render() {
    const { repherrals, loading } = this.state;

    return (
      <div>
        {loading && (
          <div style={{ textAlign: 'center' }}>Loading ...</div>
        )}
        {repherrals && (
          <div>
            {repherrals.map(repherral => (
              <div
                key={repherral.id}
                style={{ marginBottom: '20px' }}
              >
                <RepherralItem
                  authUser={this.props.authUser}
                  repherral={repherral}
                  onRemoveRepherral={this.props.onRemoveRepherral}
                />
              </div>
            ))}
          </div>
        )}
        {!loading && repherrals && !this.state.startScrolling && (
          <Button
            style={{
              fullWidth: true,
            }}
            variant="outlined"
            fullWidth
            color="primary"
            onClick={this.onNextPage}
          >
            Load more
          </Button>
        )}
        {!repherrals && <div>You have no referral requests ...</div>}
      </div>
    );
  }
}

export default withFirebase(MyRepherralList);
