import React from 'react';

import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { withFirebase } from '../Firebase';

class EmailVerificationBanner extends React.Component {
	constructor(props) {
		super(props);

		this.state = { isSent: false };
	}

	onSendEmailVerification = () => {
		this.props.firebase
			.doSendEmailVerification()
			.then(() => this.setState({ isSent: true }));
	};

	render() {
		return (
			<Toolbar
				style={{
					backgroundColor: '#dddddd',
					justifyContent: 'center',
				}}
			>
				<div style={{ textAlign: 'center', marginBottom: '15px' }}>
					<p>
						Verify your email! Check your inbox (or spam) for a
						confirmation email we sent you. Refresh this page once you
						confirmed your email.
					</p>
					<Button
						size="small"
						color="secondary"
						variant="raised"
						onClick={this.onSendEmailVerification}
						disabled={this.state.isSent}
					>
						{this.state.isSent ? 'Sent' : 'Send Again'}
					</Button>
				</div>
			</Toolbar>
		);
	}
}

export default withFirebase(EmailVerificationBanner);
