import React, { Component } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import Repherrals from '../Repherrals';

class CompanyPage extends Component {
	render() {
		const company = this.props.match.params.company;
		return (
			<div>
				<Repherrals showCompanyList company={company} />
			</div>
		);
	}
}

export default compose(withFirebase)(CompanyPage);
