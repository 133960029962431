import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import * as ROUTES from '../../constants/routes';
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../Firebase';
import EmailVerificationBanner from './EmailVerificationBanner';
import AboutBanner from './AboutBanner';
import { withRouter } from 'react-router-dom';
import logo from '../../constants/logogo.png';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class NavBar extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.setState({ anchorEl: null });
    this.props.firebase.doSignOut();
  };

  render() {
    const { classes, authUser } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className={classes.root}>
        <AppBar position="static" style={{ alignItems: 'center' }}>
          <Grid
            container
            spacing={12}
            alignItems="center"
            alignContent="center"
            direction="column"
            style={{ width: '100%', backgroundImage: 'url("https://i.imgur.com/wsmwXaD.jpg")', backgroundColor: '#aaaaaa' }}
          >
            <Grid
              item
              xs={12}
              style={{
                width: '100%',
              }}
            >
              <Toolbar>
                <Button
                  className={classes.menuButton}
                  component={Link}
                  to={ROUTES.HOME}
                  color="inherit"
                >
                  <img src={logo} style={{
                    marginBottom: '5px',
                    marginRight: '5px',
                  }}
                  />
                  <p
                    style={{
                      fontFamily: 'Roboto Slab',
                      fontSize: 14,
                      fontWeight: 1000,
                      color: '#ffffff',
                    }}
                  >
                    {' REPHER'}
                  </p>
                </Button>
                <div className={classes.grow} />
                {!authUser && (
                  <div>
                    <Button
                      component={Link}
                      to={ROUTES.FAQ}
                      color="inherit"
                    >
                      FAQ
                    </Button>
                  </div>
                )}

                {!authUser && (
                  <div>
                    <Button
                      component={Link}
                      to={ROUTES.LOGIN}
                      color="inherit"
                    >
                      Login
                    </Button>
                  </div>
                )}

                {!authUser && (
                  <div style={{ marginRight: '-10px' }}>
                    <Button
                      component={Link}
                      to={ROUTES.REGISTER}
                      color="inherit"
                    >
                      Register
                    </Button>
                  </div>
                )}

                {authUser && (
                  <div style={
                    window.innerWidth <= 420 ?
                    { marginRight: '10px' }
                    :
                    { marginRight: '15px' }
                  }>
                    <Button
                      component={Link}
                      to={ROUTES.REPHERRAL_CREATE}
                      color="secondary"
                      variant="contained"
                      size={window.innerWidth <= 420 ? 'small' : 'medium'}
                      >
                      {window.innerWidth <= 320 ? 'Create' : 'Refer Me'}
                    </Button>
                  </div>
                )}

                {authUser && (
                  <div style={
                    window.innerWidth <= 420 ?
                    { marginRight: '5px' }
                    :
                    { marginRight: '15px' }
                  }>
                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="blank">
                      <input type="hidden" name="cmd" value="_donations" />
                      <input type="hidden" name="business" value="repher.me@gmail.com" />
                      <input type="hidden" name="currency_code" value="USD" />
                      <Button
                        style={{
                            backgroundColor: "#4caf50",
                        }}
                        type="submit"
                        color="primary"
                        size={window.innerWidth <= 420 ? 'small' : 'medium'}
                        variant="contained"
                      >
                        Donate
                      </Button>
                    </form>
                  </div>
                )}

                {authUser && (
                  <div>
                    <IconButton
                      style={
                        window.innerWidth <= 420 ?
                        { marginRight: '-10px' }
                        :
                        { marginRight: '-10px' }
                      }
                      aria-owns={open ? 'menu-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={this.handleMenu}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={this.handleClose}
                    >
                      <MenuItem
                        button
                        component={Link}
                        to={ROUTES.ACCOUNT}
                        onClick={this.handleClose}
                      >
                        My Account
                      </MenuItem>
                      <MenuItem
                        button
                        component={Link}
                        to={ROUTES.FAQ}
                        onClick={this.handleClose}
                      >
                        FAQ
                      </MenuItem>
                      <MenuItem onClick={this.handleLogout}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </Toolbar>
            </Grid>
            {!authUser && <AboutBanner />}
          </Grid>
        </AppBar>
        {authUser && !authUser.emailVerified && (
          <EmailVerificationBanner />
        )}
      </div>
    );
  }
}

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withFirebase(withRouter(withStyles(styles)(NavBar)));
