import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AuthUserContext } from '../Session';
import NavBar from './NavBar';

const styles = {
  root: {
    flexGrow: 1,
    marginLeft: '-8px',
    marginRight: '-8px',
    marginTop: '-10px',
  },
  grow: {
    flexGrow: 1,
  },
};

class Navigation extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AuthUserContext.Consumer>
          {authUser => (
            <div>
              <NavBar authUser={authUser} position="static" />
            </div>
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigation);
