import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withFirebase } from '../Firebase';

class AdviceModal extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClick = () => {
    this.props.onClose(true);
  };

  handleClose = () => {
    this.props.onClose(false);
  };

  render() {
    return (
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-advice-title"
          aria-describedby="alert-dialog-advice-description"
        >
          <DialogTitle id="alert-dialog-advice-title">
            {'📈 Boost your profile'}
          </DialogTitle>
          <DialogContent>
              <p style={{ marginBottom: '20px'}}>
                1. Share your referral link on Blind for <b>3x increase</b> in chances of referral.
              </p>
              <p style={{ marginBottom: '20px'}}>
                2. Polish your LinkedIn profile, referrers will start visiting!
              </p>
              <p style={{ marginBottom: '30px'}}>
                3. Donations are appreciated! This site is 100% not-for-profit.
              </p>
              <p style={{ textAlign: 'center', fontFamily: 'Roboto Slab', marginBottom: '30px'}}>
                Donors get 1:1 career coaching from an ex-Google tech lead.
              </p>
              <p style={{ textAlign: 'center', fontSize: '10px'}}>
                Don't worry, your profile is only visible to verified employees of the companies you requested.
              </p>
              <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                style={{ marginTop: '25px' }}
                variant="contained"
                onClick={this.handleClick}
                color="secondary"
              >
                GOOD LUCK
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      );
  }
}

export default withFirebase(AdviceModal);
