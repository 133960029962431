export const LIST = [
  {
    key: '23andme',
    domain: '23andme.com',
    name: '23andMe',
    logo_url: 'https://logo.clearbit.com/23andme.com',
  },
  {
    key: 'airbnb',
    domain: 'airbnb.com',
    name: 'Airbnb',
    logo_url: 'https://logo.clearbit.com/airbnb.fr',
    is_destination: true,
  },
  {
    key: 'amazon',
    domain: 'amazon.com',
    name: 'Amazon',
    logo_url: 'https://media.glassdoor.com/sql/6036/amazon-squarelogo-1552847650117.png',
    is_destination: true,
  },
  {
    key: 'apple',
    domain: 'apple.com',
    name: 'Apple',
    logo_url: 'https://logo.clearbit.com/apple.com',
    is_destination: true,
  },
  {
    key: 'appdynamics',
    domain: 'appdynamics.com',
    name: 'AppDynamics',
    logo_url: 'https://media.glassdoor.com/sql/319551/appdynamics-squarelogo-1522878617597.png',
  },
  {
    key: 'adobe',
    domain: 'adobe.com',
    name: 'Adobe',
    logo_url: 'https://media.glassdoor.com/sql/1090/adobe-squarelogo-1439410514152.png',
    is_destination: true,
  },
  {
    key: 'accenture',
    domain: 'accenture.com',
    name: 'Accenture',
    logo_url: 'https://media.glassdoor.com/sql/4138/accenture-squarelogo-1561558417537.png',
  },
  {
    key: 'asana',
    domain: 'asana.com',
    name: 'Asana',
    logo_url: 'https://media.glassdoor.com/sql/567443/asana-squarelogo-1542656858826.png',
  },
  {
    key: 'att',
    domain: 'att.com',
    name: 'AT&T',
    logo_url: 'https://logo.clearbit.com/att.com',
  },
  {
    key: 'autodesk',
    domain: 'autodesk.com',
    name: 'Autodesk',
    logo_url: 'https://logo.clearbit.com/autodesk.com',
  },
  {
    key: 'betterment',
    domain: 'betterment.com',
    name: 'Betterment',
    logo_url: 'https://logo.clearbit.com/betterment.com',
  },
  {
    key: 'blizzard',
    domain: 'blizzard.com',
    name: 'Blizzard',
    logo_url: 'https://logo.clearbit.com/blizzard.com',
  },
  {
    key: 'bloomberg',
    domain: 'bloomberg.net',
    name: 'Bloomberg',
    logo_url:
      'https://media.glassdoor.com/sql/3096/bloomberg-l-p-squarelogo-1485356219895.png',
    is_destination: true,
  },
  {
    key: 'booking',
    domain: 'booking.com',
    name: 'Booking.com',
    logo_url: 'https://logo.clearbit.com/booking.com',
  },
  {
    key: 'coinbase',
    domain: 'coinbase.com',
    name: 'Coinbase',
    logo_url: 'https://media.glassdoor.com/sql/779622/coinbase-squarelogo-1525466423037.png',
    is_destination: true,
  },
  {
    key: 'capitalone',
    domain: 'capitalone.com',
    name: 'Capital One',
    logo_url: 'https://media.glassdoor.com/sql/3736/capital-one-squarelogo-1530277352916.png',
    is_destination: true,
  },
  {
    key: 'cisco',
    domain: 'cisco.com',
    name: 'Cisco',
    logo_url: 'https://media.glassdoor.com/sql/1425/cisco-systems-squarelogo-1479855777953.png',
    is_destination: true,
  },
  {
    key: 'citadel',
    domain: 'citadel.com',
    name: 'Citadel',
    logo_url: 'https://media.glassdoor.com/sql/14937/citadel-squarelogo-1481726523156.png',
    is_destination: true,
  },
  {
    key: 'chase',
    domain: 'chase.com',
    name: 'Chase',
    logo_url: 'https://logo.clearbit.com/chase.com',
  },
  {
    key: 'cognizant',
    domain: 'cognizant.com',
    name: 'Cognizant',
    logo_url: 'https://logo.clearbit.com/cognizant.com',
  },
  {
    key: 'creditkarma',
    domain: 'creditkarma.com',
    name: 'Credit Karma',
    logo_url:
      'https://media.glassdoor.com/sql/466574/credit-karma-squarelogo-1481097292290.png',
  },
  {
    key: 'getcruise',
    domain: 'getcruise.com',
    name: 'Cruise Automation',
    logo_url: 'https://media.glassdoor.com/sql/977351/cruise-squarelogo-1561400573348.png',
    is_destination: true,
  },
  {
    key: 'dell',
    domain: 'dell.com',
    name: 'Dell',
    logo_url: 'https://logo.clearbit.com/dell.com',
    is_destination: true,
  },
  {
    key: 'deloitte',
    domain: 'deloitte.com',
    name: 'Deloitte',
    logo_url: 'https://media.glassdoor.com/sql/2763/deloitte-squarelogo-1481880537463.png',
    is_destination: true,
  },
  {
    key: 'discordapp',
    domain: 'discordapp.com',
    name: 'Discord',
    logo_url: 'https://media.glassdoor.com/sql/910317/discord-squarelogo-1497339636473.png',
  },
  {
    key: 'disney',
    domain: 'disney.com',
    name: 'Disney',
    logo_url: 'https://logo.clearbit.com/disney.com',
    is_destination: true,
  },
  {
    key: 'docusign',
    domain: 'docusign.com',
    name: 'DocuSign',
    logo_url: 'https://logo.clearbit.com/docusign.com',
  },
  {
    key: 'doordash',
    domain: 'doordash.com',
    name: 'Doordash',
    logo_url:
      'https://media.glassdoor.com/sql/813073/doordash-squarelogo-1516170457276.png',
    is_destination: true,
  },
  {
    key: 'dropbox',
    domain: 'dropbox.com',
    name: 'Dropbox',
    logo_url: 'https://logo.clearbit.com/dropbox.com',
    is_destination: true,
  },
  {
    key: 'ea',
    domain: 'ea.com',
    name: 'EA',
    logo_url: 'https://logo.clearbit.com/ea.com',
  },
  {
    key: 'ebay',
    domain: 'ebay.com',
    name: 'eBay',
    logo_url:
      'https://media.glassdoor.com/sql/7853/ebay-squarelogo-1535144327296.png',
    is_destination: true,
  },
  {
    key: 'expedia',
    domain: 'expedia.com',
    name: 'Expedia',
    logo_url: 'https://logo.clearbit.com/expedia.com',
    is_destination: true,
  },
  {
    key: 'ey',
    domain: 'ey.com',
    name: 'EY',
    logo_url: 'https://logo.clearbit.com/ey.com',
  },
  {
    key: 'facebook',
    domain: 'fb.com',
    name: 'Facebook',
    logo_url: 'https://logo.clearbit.com/fb.com',
    is_destination: true,
  },
  {
    key: 'flexport',
    domain: 'flexport.com',
    name: 'Flexport',
    logo_url: 'https://logo.clearbit.com/flexport.com',
  },
  {
    key: 'fitbit',
    domain: 'fitbit.com',
    name: 'Fitbit',
    logo_url: 'https://logo.clearbit.com/fitbit.com',
  },
  {
    key: 'google',
    domain: 'google.com',
    name: 'Google',
    logo_url: 'https://media.glassdoor.com/sql/9079/google-squarelogo-1441130773284.png',
    is_destination: true,
  },
  {
    key: 'ge',
    domain: 'ge.com',
    name: 'GE',
    logo_url: 'https://logo.clearbit.com/ge.com',
  },
  {
    key: 'github',
    domain: 'github.com',
    name: 'GitHub',
    logo_url: 'https://media.glassdoor.com/sql/671945/github-squarelogo-1449862218536.png',
  },
  {
    key: 'glassdoor',
    domain: 'glassdoor.com',
    name: 'Glassdoor',
    logo_url: 'https://media.glassdoor.com/sql/100431/glassdoor-squarelogo-1552618157617.png',
  },
  {
    key: 'goldmansachs',
    domain: 'goldmansachs.com',
    name: 'Goldman Sachs',
    logo_url: 'https://logo.clearbit.com/goldmansachs.com',
  },
  {
    key: 'groupon',
    domain: 'groupon.com',
    name: 'Groupon',
    logo_url: 'https://media.glassdoor.com/sql/301291/groupon-squarelogo-1452288401060.png',
  },
  {
    key: 'gusto',
    domain: 'gusto.com',
    name: 'Gusto',
    logo_url: 'https://media.glassdoor.com/sql/1069704/gusto-squarelogo-1563994345250.png',
  },
  {
    key: 'hulu',
    domain: 'hulu.com',
    name: 'Hulu',
    logo_url: 'https://media.glassdoor.com/sql/43242/hulu-squarelogo-1561078825650.png',
  },
  {
    key: 'hootsuite',
    domain: 'hootsuite.com',
    name: 'Hootsuite',
    logo_url: 'https://logo.clearbit.com/hootsuite.com',
  },
  {
    key: 'houzz',
    domain: 'houzz.com',
    name: 'Houzz',
    logo_url: 'https://logo.clearbit.com/houzz.com',
  },
  {
    key: 'hpe',
    domain: 'hpe.com',
    name: 'HPE',
    logo_url: 'https://media.glassdoor.com/sql/1093046/hewlett-packard-enterprise-squarelogo-1446452961178.png',
  },
  {
    key: 'hudson-trading',
    domain: 'hudson-trading.com',
    name: 'Hudson River Trading',
    logo_url: 'https://media.glassdoor.com/sql/470937/hudson-river-trading-squarelogo-1406309640662.png',
  },
  {
    key: 'ibm',
    domain: 'ibm.com',
    name: 'IBM',
    logo_url: 'https://media.glassdoor.com/sql/354/ibm-squarelogo-1542640780332.png',
    is_destination: true,
  },  {
    key: 'indeed',
    domain: 'indeed.com',
    name: 'Indeed',
    logo_url: 'https://media.glassdoor.com/sql/100561/indeed-squarelogo.png',
    is_destination: true,
  },
  {
    key: 'instacart',
    domain: 'instacart.com',
    name: 'Instacart',
    logo_url: 'https://media.glassdoor.com/sql/714486/instacart-squarelogo-1453239842138.png',
  },
  {
    key: 'intel',
    domain: 'intel.com',
    name: 'Intel',
    logo_url:
      'https://media.glassdoor.com/sql/1519/intel-corporation-squarelogo-1515609455196.png',
      is_destination: true,
  },
  {
    key: 'intuit',
    domain: 'intuit.com',
    name: 'Intuit',
    logo_url:
      'https://media.glassdoor.com/sql/2293/intuit-squarelogo-1512517543585.png',
      is_destination: true,
  },
  {
    key: 'janestreet',
    domain: 'janestreet.com',
    name: 'Jane Street',
    logo_url: 'https://media.glassdoor.com/sql/255549/jane-street-squarelogo-1537540489979.png',
  },
  {
    key: 'juniper',
    domain: 'juniper.net',
    name: 'Juniper Networks',
    logo_url: 'https://logo.clearbit.com/juniper.net',
  },
  {
    key: 'kpmg',
    domain: 'kpmg.com',
    name: 'KPMG',
    logo_url: 'https://media.glassdoor.com/sql/2867/kpmg-squarelogo-1539879123763.png',
  },
  {
    key: 'lyft',
    domain: 'lyft.com',
    name: 'Lyft',
    logo_url:
      'https://media.glassdoor.com/sql/700614/lyft-squarelogo-1540843891567.png',
    is_destination: true,
    },
  {
    key: 'linkedin',
    domain: 'linkedin.com',
    name: 'LinkedIn',
    logo_url: 'https://logo.clearbit.com/linkedin.com',
    is_destination: true,
  },
  {
    key: 'lime',
    domain: 'li.me',
    name: 'Lime',
    logo_url:
      'https://is2-ssl.mzstatic.com/image/thumb/Purple124/v4/8b/d1/e5/8bd1e59e-5a49-878c-c27b-45599bfc0733/source/512x512bb.jpg',
  },
  {
    key: 'lendingclub',
    domain: 'lendingclub.com',
    name: 'Lending Club',
    logo_url: 'https://media.glassdoor.com/sql/349061/lendingclub-squarelogo-1557940747019.png',
  },
  {
    key: 'microsoft',
    domain: 'microsoft.com',
    name: 'Microsoft',
    logo_url: 'https://logo.clearbit.com/microsof.com',
    is_destination: true,
    },
  {
    key: 'mozilla',
    domain: 'mozilla.com',
    name: 'Mozilla',
    logo_url:
      'https://media.glassdoor.com/sql/19129/mozilla-squarelogo-1520983965099.png',
      is_destination: true,
  },
  {
    key: 'magicleap',
    domain: 'magicleap.com',
    name: 'Magic Leap',
    logo_url: 'https://logo.clearbit.com/magicleap.com',
  },
  {
    key: 'medallia',
    domain: 'medallia.com',
    name: 'Medallia',
    logo_url:
      'https://media.glassdoor.com/sql/268806/medallia-squarelogo-1399059625068.png',
  },
  {
    key: 'morganstanley',
    domain: 'morganstanley.com',
    name: 'Morgan Stanley',
    logo_url: 'https://logo.clearbit.com/morganstanley.com',
  },
  {
    key: 'netflix',
    domain: 'netflix.com',
    name: 'Netflix',
    logo_url: 'https://logo.clearbit.com/netflix.com',
    is_destination: true,
  },
  {
    key: 'nerdwallet',
    domain: 'nerdwallet.com',
    name: 'Nerdwallet',
    logo_url: 'https://logo.clearbit.com/nerdwallet.com',
  },
  {
    key: 'nextdoor',
    domain: 'nextdoor.com',
    name: 'Nextdoor',
    logo_url: 'https://logo.clearbit.com/nextdoor.com',
  },
  {
    key: 'nike',
    domain: 'nike.com',
    name: 'Nike',
    logo_url: 'https://logo.clearbit.com/nike.com',
  },
  {
    key: 'nintendo',
    domain: 'nintendo.com',
    name: 'Nintendo',
    logo_url: 'https://logo.clearbit.com/nintendo.com',
  },
  {
    key: 'nvidia',
    domain: 'nvidia.com',
    name: 'Nvidia',
    logo_url:
      'https://media.glassdoor.com/sql/7633/nvidia-squarelogo-1474909715686.png',
    is_destination: true,
  },
  {
    key: 'oath',
    domain: 'oath.com',
    name: 'Oath',
    logo_url: 'https://media.glassdoor.com/sql/1778634/oath-squarelogo-1522741508637.png',
  },
  {
    key: 'okta',
    domain: 'okta.com',
    name: 'Okta',
    logo_url: 'https://media.glassdoor.com/sql/444756/okta-squarelogo-1447089717738.png',
  },
  {
    key: 'opendoor',
    domain: 'opendoor.com',
    name: 'Opendoor',
    logo_url: 'https://logo.clearbit.com/opendoor.com',
  },
  {
    key: 'oracle',
    domain: 'oracle.com',
    name: 'Oracle',
    logo_url: 'https://media.glassdoor.com/sql/1737/oracle-squarelogo-1466430570527.png',
    is_destination: true,
  },
  {
    key: 'palantir',
    domain: 'palantir.com',
    name: 'Palantir',
    logo_url:
      'https://media.glassdoor.com/sql/236375/palantir-technologies-squarelogo.png',
  },
  {
    key: 'pandora',
    domain: 'pandora.com',
    name: 'Pandora',
    logo_url: 'https://logo.clearbit.com/pandora.com',
  },
  {
    key: 'paypal',
    domain: 'paypal.com',
    name: 'PayPal',
    logo_url:
      'https://media.glassdoor.com/sql/9848/paypal-squarelogo-1440712027841.png',
    is_destination: true,
  },
  {
    key: 'pinterest',
    domain: 'pinterest.com',
    name: 'Pinterest',
    logo_url: 'https://logo.clearbit.com/pinterest.com',
    is_destination: true,
  },
  {
    key: 'pixar',
    domain: 'pixar.com',
    name: 'Pixar',
    logo_url:
      'https://media.glassdoor.com/sql/5118/pixar-animation-studios-squarelogo-1531783332576.png',
  },
  {
    key: 'postmates',
    domain: 'postmates.com',
    name: 'Postmates',
    logo_url: 'https://logo.clearbit.com/postmates.com',
  },
  {
    key: 'pwc',
    domain: 'pwc.com',
    name: 'PwC',
    logo_url:
      'https://media.glassdoor.com/sql/8450/pwc-squarelogo.png',
  },
  {
    key: 'qualcomm',
    domain: 'qualcomm.com',
    name: 'Qualcomm',
    logo_url: 'https://logo.clearbit.com/qualcomm.com',
  },
  {
    key: 'qualtrics',
    domain: 'qualtrics.com',
    name: 'Qualtrics',
    logo_url:
      'https://media.glassdoor.com/sql/323717/qualtrics-squarelogo-1550356717678.png',
  },
  {
    key: 'quora',
    domain: 'quora.com',
    name: 'Quora',
    logo_url: 'https://media.glassdoor.com/sql/646885/quora-inc-squarelogo-1434756620675.png',
    is_destination: true,
  },
  {
    key: 'rakuten',
    domain: 'rakuten.com',
    name: 'Rakuten',
    logo_url: 'https://media.glassdoor.com/sql/40197/rakuten-squarelogo-1551663295218.png',
  },
  {
    key: 'reddit',
    domain: 'reddit.com',
    name: 'Reddit',
    logo_url: 'https://media.glassdoor.com/sql/796358/reddit-squarelogo-1490630845152.png',
  },
  {
    key: 'redfin',
    domain: 'redfin.com',
    name: 'Redfin',
    logo_url: 'https://media.glassdoor.com/sql/150726/redfin-squarelogo-1463082142762.png',
  },
  {
    key: 'robinhood',
    domain: 'robinhood.com',
    name: 'Robinhood',
    logo_url: 'https://media.glassdoor.com/sql/1167765/robinhood-squarelogo-1530549970728.png',
    is_destination: true,
  },
  {
    key: 'roku',
    domain: 'roku.com',
    name: 'Roku',
    logo_url: 'https://media.glassdoor.com/sql/26760/roku-squarelogo.png',
  },
  {
    key: 'salesforce',
    domain: 'salesforce.com',
    name: 'Salesforce',
    logo_url:
      'https://media.glassdoor.com/sql/11159/salesforce-com-squarelogo-1413910133565.png',
      is_destination: true,
    },
  {
    key: 'samsung',
    domain: 'samsung.com',
    name: 'Samsung',
    logo_url: 'https://logo.clearbit.com/samsungpromotions.com',
    is_destination: true,
  },
  {
    key: 'sap',
    domain: 'sap.com',
    name: 'SAP',
    logo_url: 'https://www.pepperi.com/wp-content/uploads/2016/07/SAP.png',
    is_destination: true,
  },
  {
    key: 'slack',
    domain: 'slack.com',
    name: 'Slack',
    logo_url: 'https://logo.clearbit.com/slack.com',
  },
  {
    key: 'snap',
    domain: 'snap.com',
    name: 'Snap',
    logo_url: 'https://media.glassdoor.com/sql/671946/snap-squarelogo-1541114665707.png',
    is_destination: true,
  },
  {
    key: 'snapchat',
    domain: 'snapchat.com',
    name: 'Snapchat',
    logo_url: 'https://media.glassdoor.com/sql/671946/snap-squarelogo-1541114665707.png',
    is_destination: true,
  },
  {
    key: 'spacex',
    domain: 'spacex.com',
    name: 'SpaceX',
    logo_url: 'https://logo.clearbit.com/spacex.com',
  },
  {
    key: 'splunk',
    domain: 'splunk.com',
    name: 'Splunk',
    logo_url: 'https://media.glassdoor.com/sql/117313/splunk-squarelogo.png',
    is_destination: true,
  },
  {
    key: 'spotify',
    domain: 'spotify.com',
    name: 'Spotify',
    logo_url: 'https://logo.clearbit.com/spotify.com',
    is_destination: true,
  },
  {
    key: 'squareup',
    domain: 'squareup.com',
    name: 'Square',
    logo_url: 'https://media.glassdoor.com/sql/422050/square-squarelogo-1481161604552.png',
    is_destination: true,
  },
  {
    key: 'squarespace',
    domain: 'squarespace.com',
    name: 'Squarespace',
    logo_url: 'https://logo.clearbit.com/squarespace.com',
  },
  {
    key: 'stripe',
    domain: 'stripe.com',
    name: 'Stripe',
    logo_url: 'https://media.glassdoor.com/sql/671932/stripe-squarelogo-1481840532607.png',
    is_destination: true,
  },
  {
    key: 'stubhub',
    domain: 'stubhub.com',
    name: 'StubHub',
    logo_url: 'https://logo.clearbit.com/stubhub.com',
  },
  {
    key: 'surveymonkey',
    domain: 'surveymonkey.com',
    name: 'SurveyMonkey',
    logo_url: 'https://logo.clearbit.com/surveymonkey.com',
  },
  {
    key: 'symantec',
    domain: 'symantec.com',
    name: 'Symantec',
    logo_url: 'https://logo.clearbit.com/symantec.com',
  },
  {
    key: 'shopify',
    domain: 'shopify.com',
    name: 'Shopify',
    logo_url: 'https://media.glassdoor.com/sql/675933/shopify-squarelogo-1579284622154.png',
  },
  {
    key: 'tiktok',
    domain: 'tiktok.com',
    name: 'TikTok',
    logo_url: 'https://media.glassdoor.com/sql/2230881/tiktok-squarelogo-1539940701771.png',
  },
  {
    key: 'tableau',
    domain: 'tableau.com',
    name: 'Tableau',
    logo_url:
      'https://media.glassdoor.com/sql/38125/tableau-software-squarelogo-1490118619041.png',
    is_destination: true,
  },
  {
    key: 'tesla',
    domain: 'tesla.com',
    name: 'Tesla',
    logo_url: 'https://logo.clearbit.com/tesla.com',
    is_destination: true,
  },
  {
    key: 'thumbtack',
    domain: 'thumbtack.com',
    name: 'Thumbtack',
    logo_url: 'https://logo.clearbit.com/thumbtack.com',
  },
  {
    key: 'tripadvisor',
    domain: 'tripadvisor.com',
    name: 'TripAdvisor',
    logo_url: 'https://media.glassdoor.com/sql/15276/tripadvisor-squarelogo-1496150455363.png',
  },
  {
    key: 'twilio',
    domain: 'twilio.com',
    name: 'Twilio',
    logo_url: 'https://media.glassdoor.com/sql/410790/twilio-squarelogo-1429071171061.png',
    is_destination: true,
  },
  {
    key: 'twitch',
    domain: 'twitch.tv',
    name: 'Twitch',
    logo_url: 'https://logo.clearbit.com/twitch.tv',
  },
  {
    key: 'twitter',
    domain: 'twitter.com',
    name: 'Twitter',
    logo_url: 'https://logo.clearbit.com/twitter.com',
    is_destination: true,
  },
  {
    key: 'twosigma',
    domain: 'twosigma.com',
    name: 'Two Sigma',
    logo_url:
      'https://media.glassdoor.com/sql/241045/two-sigma-squarelogo-1384202727278.png',
      is_destination: true,
    },
  {
    key: 'uber',
    domain: 'uber.com',
    name: 'Uber',
    logo_url: 'https://media.glassdoor.com/sql/575263/uber-squarelogo-1537216184790.png',
    is_destination: true,
  },
  {
    key: 'unity',
    domain: 'unity.com',
    name: 'Unity',
    logo_url: 'https://logo.clearbit.com/unity.com',
  },
  {
    key: 'veritas',
    domain: 'veritas.com',
    name: 'Veritas',
    logo_url: 'https://media.glassdoor.com/sql/1093041/veritas-squarelogo-1517432144282.png',
  },
  {
    key: 'verizon',
    domain: 'verizon.com',
    name: 'Verizon',
    logo_url: 'https://media.glassdoor.com/sql/89/verizon-squarelogo-1487720317378.png',
  },
  {
    key: 'visa',
    domain: 'visa.com',
    name: 'VISA',
    logo_url: 'https://logo.clearbit.com/visa.com',
    is_destination: true,
  },
  {
    key: 'vmware',
    domain: 'vmware.com',
    name: 'VMware',
    logo_url: 'https://logo.clearbit.com/vmware.com',
    is_destination: true,
  },
  {
    key: 'walmart',
    domain: 'walmart.com',
    name: 'Walmart.com',
    logo_url: 'https://logo.clearbit.com/walmart.com',
    is_destination: true,
  },
  {
    key: 'wealthfront',
    domain: 'wealthfront.com',
    name: 'Wealthfront',
    logo_url: 'https://logo.clearbit.com/wealthfront.com',
  },
  {
    key: 'wellsfargo',
    domain: 'wellsfargo.com',
    name: 'Wells Fargo',
    logo_url: 'https://logo.clearbit.com/wellsfargo.com',
  },
  {
    key: 'wework',
    domain: 'wework.com',
    name: 'WeWork',
    logo_url:
      'https://media.glassdoor.com/sql/661275/wework-squarelogo-1473691455447.png',
      is_destination: true,
  },
  {
    key: 'workday',
    domain: 'workday.com',
    name: 'Workday',
    logo_url: 'https://media.glassdoor.com/sql/197851/workday-squarelogo-1485303092465.png',
    is_destination: true,
  },
  {
    key: 'yahoo',
    domain: 'yahoo.com',
    name: 'Yahoo',
    logo_url: 'https://logo.clearbit.com/yahoo.com',
  },
  {
    key: 'yelp',
    domain: 'yelp.com',
    name: 'Yelp',
    logo_url:
      'https://media.glassdoor.com/sql/43314/yelp-squarelogo-1439320422094.png',
    is_destination: true,
  },
  {
    key: 'zenefits',
    domain: 'zenefits.com',
    name: 'Zenefits',
    logo_url: 'https://logo.clearbit.com/zenefits.com',
  },
  {
    key: 'zillow',
    domain: 'zillow.com',
    name: 'Zillow',
    logo_url: 'https://media.glassdoor.com/sql/40802/zillow-squarelogo-1554256839956.png',
  },
  {
    key: 'zoom',
    domain: 'zoom.us',
    name: 'Zoom',
    logo_url: 'https://media.glassdoor.com/sql/924644/zoom-video-communications-squarelogo-1450871590327.png',
  },
  {
    key: 'grabtaxi',
    domain: 'grabtaxi.com',
    name: 'Grab Taxi',
    logo_url: 'https://media.glassdoor.com/sql/958580/grab-squarelogo-1536799247020.png',
  },
  {
    key: 'wayfair',
    domain: 'wayfair.com',
    name: 'Wayfair',
    logo_url: 'https://media.glassdoor.com/sql/134525/wayfair-squarelogo-1521643856627.png',
  },
  {
    key: 'affirm',
    domain: 'affirm.com',
    name: 'Affirm',
    logo_url: 'https://media.glassdoor.com/sql/823564/affirm-squarelogo-1563983702512.png',
  },
];

var images = {};
LIST.forEach(function(item) {
  images[item.domain] = item.logo_url;
  images[item.name] = item.logo_url;
  images[item.key] = item.logo_url;
});

export const IMAGES = images;

var names = {};
LIST.forEach(function(item) {
  names[item.key] = item.name;
  names[item.domain] = item.name;
});

export const NAMES = names;

var keys = {};
var destinations = {};
LIST.forEach(function(item) {
  keys[item.key] = item.key;
  keys[item.domain] = item.key;
  if (item.is_destination) {
    destinations[item.key] = item.key;
    destinations[item.name.toLowerCase()] = item.key;
  }
});

export const KEYS = keys;
export const DESTS = destinations;
