import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import RepherralItem from './RepherralItem';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

class RepherralDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      repherrals: [],
    };
  }

  componentDidMount() {
    this.onListenForRepherrals();
  }

  componentWillUnmount() {
    this.props.firebase.repherrals().off();
  }

  onListenForRepherrals = () => {
    this.setState({ loading: true });

    this.props.firebase
      .repherral(this.props.id)
      .once('value', snapshot => {
        const repherralObject = snapshot.val();
        if (repherralObject) {
          repherralObject.id = this.props.id;
          this.setState({
            repherrals: [repherralObject],
            loading: false,
          });
        } else {
          this.setState({ repherrals: null, loading: false });
        }
      });
  };

  onClick = () => {
    this.props.history.push({
        pathname: ROUTES.HOME,
    });
};

  render() {
    const { repherrals, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            {loading && (
              <div style={{ textAlign: 'center' }}>Loading ...</div>
            )}
            {repherrals && (
              <div>
                {repherrals.map(repherral => (
                  <div
                    key={repherral.id}
                    style={{ marginBottom: '20px' }}
                  >
                    <RepherralItem
                      authUser={authUser}
                      repherral={repherral}
                      onRemoveRepherral={this.props.onRemoveRepherral}
                    />
                  </div>
                ))}
              </div>
            )}
            {!repherrals && (
              <div>There is no referral by that ID.</div>
            )}
            {!loading && (
              <Button
                  fullWidth
                  variant="contained"
                  onClick={this.onClick}
                  color="primary"
              >
                Explore more candidates!
              </Button>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(withRouter(RepherralDetail));
