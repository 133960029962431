import React from 'react';

import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../Firebase';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import * as ROUTES from '../../constants/routes';
import * as COMPANIES from '../../constants/companies';
import IconButton from '@material-ui/core/IconButton';

class AboutBanner extends React.Component {
	onReferMe = () => {
		this.props.history.push({
			pathname: ROUTES.REGISTER,
			state: { registrationType: 'repherree' },
		});
	};

	onCompanyClick = company => {
		this.props.history.push({
		  pathname: '/to/' + company,
		});
	  };
	
	render() {
		const avatar = {
			margin: '15px',
			width: 50,
			height: 50,
			border: '2px solid #ffffff',
			backgroundOrigin: 'border-box',
			backgroundClip: 'content-box, border-box',
		};

		var aboutTitle = '';
		var proof = '';
		switch (this.props.location.pathname) {
			case '/':
				aboutTitle = 'Get referrals to top tech companies';
				proof = 'from 5000+ verified professionals at'
				break;
			case '/faq':
				aboutTitle = 'FAQ';
				break;
			case '/login':
				aboutTitle = 'Login';
				break;
			case '/register':
				aboutTitle = 'Register';
				break;
			case '/forgot-password':
				aboutTitle = 'Forgot your password?';
				break;
			default:
				break;
		}

		return (
			<div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
				{aboutTitle !== '' && (
					<Grid
						item
						xs={12}
						style={{
							width: '100%',
							textAlign: 'center',
						}}
					>
						<p
							style={{
								marginBottom: '10px',
								marginTop: '10px',
								fontFamily: 'Roboto Slab',
								fontSize: 38,
								color: '#ffffff',
							}}
						>
							{aboutTitle}
						</p>
						<p
							style={{
								marginBottom: '10px',
								marginTop: '10px',
								fontFamily: 'Roboto Slab',
								fontSize: 20,
								color: '#ffffff',
							}}
						>
							{proof}
						</p>
					</Grid>
				)}
				{this.props.location.pathname === '/' && (
					<div>
						<Grid container justify="center" alignItems="center">
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('google')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['google']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('facebook')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['facebook']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('snap')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['snap']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('pinterest')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['pinterest']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('stripe')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['stripe']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('amazon')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['amazon']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('microsoft')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['microsoft']} />
						</IconButton>
						<IconButton
							style={{ padding: 0 }}
							onClick={() => this.onCompanyClick('apple')}
						>
							<Avatar style={avatar} src={COMPANIES.IMAGES['apple']} />
						</IconButton>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								width: '100%',
								textAlign: 'center',
							}}
						>
							<p
								style={{
									marginBottom: '10px',
									marginTop: '10px',
									fontFamily: 'Roboto Slab',
									fontSize: 14,
									color: '#ffffff',
								}}
							>
								✅ Increase your chances of landing an interview
							</p>
							<p
								style={{
									marginBottom: '10px',
									marginTop: '10px',
									fontFamily: 'Roboto Slab',
									fontSize: 14,
									color: '#ffffff',
								}}
							>
								🔒Your profile's only visible to potential referrers
							</p>
							<p
								style={{
									marginBottom: '10px',
									marginTop: '10px',
									fontFamily: 'Roboto Slab',
									fontSize: 14,
									color: '#ffffff',
								}}
							>
								🆓 Free to use, forever (we promise)
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								marginBottom: '10px',
								marginTop: '30px',
								width: '100%',
								textAlign: 'center',
							}}
						>
							<Button
								style={{
									minHeight: '40px',
									minWidth: '100px',
								}}
								size="large"
								color="secondary"
								variant="contained"
								onClick={this.onReferMe}
							>
								Refer me
							</Button>
						</Grid>
						<Typography
							color="inherit"
							variant="overline"
							style={{ textAlign: 'center' }}
						>
							<span style={{ fontSize: '20px' }}>👇</span>
							... or give referrals{' '}
							<span style={{ fontSize: '20px' }}>👇</span>
						</Typography>{' '}
					</div>
				)}
			</div>
		);
	}
}

export default withFirebase(withRouter(AboutBanner));
