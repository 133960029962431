import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';

import Navigation from '../Navigation';
import RegisterPage from '../Register';
import FAQPage from '../FAQ';
import LoginPage from '../Login';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import CreateRepherralPage from '../CreateRepherral';
import RepherralDetailsPage from '../Details';
import CompanyPage from '../Company';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    alignItems: 'center',
  },
});

const theme = createMuiTheme({
overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
      }
    }
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#171e47',
    },
  },
});

class App extends Component {
  render() {
    const { classes } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div>
            <Navigation />
            <div className={classes.root}>
              <Grid
                container
                spacing={12}
                alignItems="center"
                direction="column"
                style={{ width: '100%', paddingBottom:'20px' }}
              >
                <Grid item xs={12}>
                  <Route
                    exact
                    path={ROUTES.HOME}
                    component={HomePage}
                  />
                  <Route
                    path={ROUTES.REGISTER}
                    component={RegisterPage}
                  />
                  <Route path={ROUTES.FAQ} component={FAQPage} />
                  <Route path={ROUTES.LOGIN} component={LoginPage} />
                  <Route
                    path={ROUTES.PASSWORD_FORGET}
                    component={PasswordForgetPage}
                  />
                  <Route
                    path={ROUTES.REPHERRAL_CREATE}
                    component={CreateRepherralPage}
                  />
                  <Route
                    exact
                    path={ROUTES.COMPANY_REPHERRALS}
                    component={CompanyPage}
                  />
                  <Route
                    exact
                    path={ROUTES.REPHERRAL_DETAIL}
                    component={RepherralDetailsPage}
                  />
                  <Route
                    path={ROUTES.ACCOUNT}
                    component={AccountPage}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withAuthentication(withStyles(styles)(App));
